import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Link } from 'react-router-dom';

import logOut from '../images/icons/log-out.svg';
import profile from '../images/icons/profile.svg';
import logo from '../images/logo.png';
import { signOutClickHandler } from '../utils';

export const Navbar: React.FC = () => {
    const { accounts, instance } = useMsal();

    const accountName = accounts?.[0]?.username || '';
    const accountId = accounts[0]?.homeAccountId;

    return (
        <>
            <div className="w-full h-[54px]"></div>
            <header className="fixed top-0 w-full bg-black-1 z-[10000]">
                <div className="w-full px-[16px] md:px-[32px] py-4 grid grid-cols-3 ">
                    <div className="justify-self-start">
                        <Link
                            to="/"
                            className="text-white text-[13px] my-auto font-bold hidden sm:inline-block"
                        >
                            Digital archive
                        </Link>
                    </div>
                    <Link to="/" className="justify-self-center">
                        <img className="cursor-pointer h-[22px]" src={logo} alt="logo" />
                    </Link>
                    <div className="flex gap-6 justify-self-end">
                        {accountName && (
                            <div className="flex text-white gap-2 cursor-pointer">
                                <img src={profile} alt="profile icon" title={accountName} />

                                <h3
                                    className="hidden md:inline-block whitespace-nowrap"
                                    title={accountName}
                                >
                                    {accountName}
                                </h3>
                            </div>
                        )}
                        {!!accountId && (
                            <div
                                className="cursor-pointer"
                                onClick={() => {
                                    localStorage.clear();
                                    signOutClickHandler(instance, accountId);
                                }}
                            >
                                <img src={logOut} alt="log out icon" srcSet="" />
                            </div>
                        )}
                    </div>
                </div>
            </header>
        </>
    );
};
export default Navbar;
