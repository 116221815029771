import { Icon } from '@fluentui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AssetVideo from '../../components/video';
import NotFoundImg from '../../images/image-not-found.png';
import { formatDate } from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../utils/extendedGlobal';

interface IProps {
    album: {
        id: string;
        name: string;
        image?: {
            imageId: string;
            file?: {
                fullName: string;
                name: string;
                extension: string;
            };
            cloudinary?: {
                publicId: string;
                version: string;
                width: number;
                height: number;
                bytes: number;
                urlPrefix: string;
                uploadedOn: Date;
                cloudName: string;
            };
            storageTypeId: string;
            resourceType: string;
        };
        lastUpdate: string;
        assetNumber: number;
        isFavouriteAlbum: boolean;
    };
}

const Album: React.FC<IProps> = ({ album }) => {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => {
                sendGoogleAnalyticsEvent('album_opened_' + album.id, 'albumName: ' + album.name);
                navigate(`${album.id}`);
            }}
            className="w-full bg-white mx-auto border border-gray-02 rounded text-gray-1 cursor-pointer group hover:border-gray-03 duration-150"
        >
            <div className="p-4">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        <div>
                            {album.name && <h3 className="font-bold text-lg">{album.name}</h3>}
                        </div>

                        {album.isFavouriteAlbum && <Icon iconName="FavoriteStarFill" />}
                    </div>
                    <div className="text-[12px] leading-[18px]">
                        {`(${album?.assetNumber ?? 0} assets)`}
                    </div>
                </div>
                <div className="text-xs text-gray-500">
                    Last update on {formatDate(album.lastUpdate)}
                </div>
            </div>

            {album?.image?.resourceType === 'image' && (
                <img
                    src={`https:${album?.image?.cloudinary?.urlPrefix}${
                        album?.image?.file?.extension === 'pdf' ? 'f_auto' : 'c_thumb'
                    },q_auto,h_336/v${album?.image?.cloudinary?.version}/${album?.image?.cloudinary
                        ?.publicId}`}
                    loading="lazy"
                    alt="Album cover"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = NotFoundImg;
                    }}
                    className="w-full object-contain h-[336px]"
                />
            )}
            {album?.image?.resourceType === 'video' && (
                <div className="w-full h-[336px] flex justify-center">
                    <AssetVideo
                        height={336}
                        storageTypeId={album?.image?.storageTypeId}
                        extension={album.image?.file?.extension}
                        cloudinary={album.image?.cloudinary}
                    />
                </div>
            )}
            {album?.image?.resourceType === 'raw' && (
                <div>
                    {/* <Icon className="w-12" /> */}
                    <p className="text-center text-gray-06 font-semibold text-sm my-4">
                        {album?.image?.file?.extension}
                    </p>
                </div>
            )}
            {!album.image && (
                <div className="text-gray-06 font-semibold text-sm text-center my-4">
                    No cover image
                </div>
            )}
        </div>
    );
};

export default Album;
