import React, { useState } from 'react';

import useScrollPosition from '../../hooks/wasScrolled';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as FiltersIcon } from '../../images/icons/filters.svg';
import { ISmartBrowsingResponse } from '../../types';
import Operations from './operations';
import Search from './search';

interface IProps {
    hideTags: boolean;
    isBigImg: boolean;
    selectedAssets: string[];
    isSearchModalOpen: boolean;
    data: ISmartBrowsingResponse;
    getSortedAssets: (sortOrder: string | null, sortField: string | null) => void;
    getSortedSmartBrowsing: (sortOrder: string | null, sortField: string | null) => void;
    getIncludeNotSetSmartBrowsing: (includeNotSet: boolean) => void;
    setBigImg: React.Dispatch<React.SetStateAction<boolean>>;
    setHideTags: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAssets: React.Dispatch<React.SetStateAction<string[]>>;
    setSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setShareModalState: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDownloadAssetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMassiveDownloadAssetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    includeNotSet: boolean;
}

const Filters: React.FC<IProps> = ({
    data,
    isBigImg,
    hideTags,
    setBigImg,
    setHideTags,
    selectedAssets,
    getSortedAssets,
    getSortedSmartBrowsing,
    getIncludeNotSetSmartBrowsing,
    isSearchModalOpen,
    setSelectedAssets,
    setSearchModalOpen,
    setShareModalState,
    setIsDownloadAssetModalOpen,
    setIsMassiveDownloadAssetModalOpen,
    includeNotSet,
}) => {
    const wasScrolled = useScrollPosition(200);
    const [viewFixedFilters, setViewFixedFilters] = useState(false);

    return (
        <div
            className={`z-40 bg-[#F7F7F7] md:pt-[10px] pb-2 hover:opacity-100  ${
                viewFixedFilters && wasScrolled ? 'fixed inset-x-0 top-[102px] shadow-lg p-3' : ''
            }`}
        >
            {wasScrolled && !viewFixedFilters && (
                <div
                    className={`fixed top-[110px] z-[1000] ${
                        viewFixedFilters ? 'right-0' : 'right-[25px]'
                    }`}
                >
                    <button
                        onClick={() => setViewFixedFilters(true)}
                        className="rounded cursor-pointer border-gray-04 p-2 border bg-white"
                    >
                        <FiltersIcon className="fill-gray-09 w-[18px] h-full" />
                    </button>
                </div>
            )}

            <Search
                data={data}
                isSearchModalOpen={isSearchModalOpen}
                setSearchModalOpen={setSearchModalOpen}
            />
            <Operations
                data={data}
                isBigImg={isBigImg}
                setBigImg={setBigImg}
                hideTags={hideTags}
                setHideTags={setHideTags}
                selectedAssets={selectedAssets}
                getSortedAssets={getSortedAssets}
                getSortedSmartBrowsing={getSortedSmartBrowsing}
                getIncludeNotSetSmartBrowsing={getIncludeNotSetSmartBrowsing}
                setSelectedAssets={setSelectedAssets}
                setShareModalState={setShareModalState}
                setSearchModalOpen={setSearchModalOpen}
                setIsDownloadAssetModalOpen={setIsDownloadAssetModalOpen}
                setIsMassiveDownloadAssetModalOpen={setIsMassiveDownloadAssetModalOpen}
                includeNotSet={includeNotSet}
            />
            {wasScrolled && viewFixedFilters && (
                <div className="flex justify-end">
                    <button
                        onClick={() => setViewFixedFilters(false)}
                        className="justify-center p-2 flex items-center gap-2 rounded cursor-pointer duration-150 bg-gray-1 text-[#ffffff] disabled:text-gray-06 disabled:cursor-default disabled:bg-gray-02 disabled:fill-gray-05"
                    >
                        <CloseIcon className="fill-white w-[12px] h-full" />
                        <div>Close Filters</div>
                    </button>
                </div>
            )}
        </div>
    );
};

export default Filters;
