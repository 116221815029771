import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';

interface IData {
    albumId: string;
}

export const getAlbumFilters = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post(`${BaseUrl}Album/GetAlbumFilters`, data, auth);
};
