import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';

interface IData {
    albumId: string;
    imageId: string;
}

interface Output {
    AlbumCoverEdited: boolean;
}

export const editAlbumCover = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post<Output>(`${BaseUrl}Album/EditAlbumCover`, data, auth);
};
