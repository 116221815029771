import React from 'react';

import { ReactComponent as ClearFilterIcon } from '../images/icons/clear-filter.svg';

interface IProps {
    onClick: () => void;
    disabled: boolean;
    notUserFullClearFIlter?: boolean;
}

const ClearFilterButton: React.FC<IProps> = ({ onClick, disabled, notUserFullClearFIlter }) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            title="Clear filter"
            className="justify-center h-[40px] px-[10px] flex items-center gap-2 rounded cursor-pointer duration-150 bg-gray-1 text-[#ffffff] disabled:text-gray-06 disabled:cursor-default disabled:bg-gray-02 disabled:fill-gray-05"
        >
            <ClearFilterIcon className={disabled ? `fill-gray-06` : `fill-white`} />
            {!notUserFullClearFIlter && (
                <span className="uppercase font-bold md:hidden text-[#ffffff] disabled:text-gray-06">
                    Clear Filter
                </span>
            )}
        </button>
    );
};

export default ClearFilterButton;
