import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';

interface IData {
    albumId: string;
}

interface Output {
    Deleted: boolean;
}

export const deleteAlbum = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post<Output>(`${BaseUrl}Album/DeleteAlbum`, data, auth);
};
