import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';
import { ITag } from '../types';

interface IData {
    pageSize: number;
    sortOrder?: string | null;
    sortField?: string | null;
    nextPaginationInfo?: string[];
    freeText?: string;
    tags: ITag[];
    albumId: string;
}

export const getAlbumAssets = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post(`${BaseUrl}Album/GetAlbumAssets`, data, auth);
};
