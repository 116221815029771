import { useIsAuthenticated } from '@azure/msal-react';
import { IconButton } from '@fluentui/react';
import { CircularProgress } from '@mui/material';
import copy from 'copy-to-clipboard';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Tabs from '../../common/tabs';
import Asset from '../../components/asset';
import AssetPreview from '../../components/asset/preview';
import DownloadAssetsModal from '../../components/downloadModal';
import { MassiveDownloadModal } from '../../components/MassiveDownloadModal';
import { HOME_TABS, PAGE_SIZE } from '../../consts';
import { CommandDataContext } from '../../context/CommandDataContext';
import { ReactComponent as BackArrow } from '../../images/icons/back-arrow.svg';
import { LoaderContext } from '../../routes';
import { getAlbum } from '../../services/getAlbum';
import { getAlbumAssets } from '../../services/getAlbumAssets';
import { allApis } from '../../store/rtk';
import { IAlbumsResponse } from '../../types';
import { notifyAxiosError } from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../utils/extendedGlobal';
import DeleteAlbumModal from './DeleteAlbumModal';
import EditAlbumName from './EditAlbumName';
import Filter from './filter';
import Operations from './operations';

const AlbumDetail: React.FC = () => {
    const [getMoreAlbumAssetsLoading, setGetMoreAlbumAssetsLoading] = useState(false);
    const [isDownloadAssetModalOpen, setIsDownloadAssetModalOpen] = useState(false);
    const [isMassiveDownloadAssetModalOpen, setIsMassiveDownloadModalOpen] = useState(false);
    const [isDeleteAlbumModalOpen, setIsDeleteAlbumModalOpen] = useState(false);
    const [selectedTab, setselectedTab] = useState<string>(HOME_TABS[2].value);
    const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
    const [isFilterModalOpen, setFilterModalOpen] = useState(false);
    const [previewedAsset, setPreviewedAsset] = useState<any>();
    const [data, setData] = useState<IAlbumsResponse>();
    const [hideTags, setHideTags] = useState(false);
    const [isBigImg, setBigImg] = useState(false);
    const [searchParams] = useSearchParams();
    const ctxt = useContext(LoaderContext);
    const { selectAllAlbumAssets } = useContext(CommandDataContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const isAuthenticated = useIsAuthenticated();

    const [isFavouriteAlbum, setIsFavouriteAlbum] = useState(false);
    const [addAlbumToFavouritesApi, addAlbumToFavouritesApiResult] =
        allApis.usePostAlbumAddAlbumToFavouritesMutation();
    const [removeAlbumFromFavouritesApi, removeAlbumFromFavouritesApiResult] =
        allApis.usePostAlbumRemoveAlbumFromFavouritesMutation();

    const params = useMemo(() => {
        const param = searchParams.get('req');
        if (param) {
            try {
                const parsedParam = JSON.parse(param);
                return parsedParam;
            } catch (err) {
                console.error(err);
            }
        } else return null;
    }, [searchParams]);

    const shareLink = location.protocol + '//' + location.host + location.pathname;

    useEffect(() => {
        if (!params) {
            getData({ id });
        } else {
            getData({ id, tags: params.tags, freeText: params.freeText });
        }
    }, [params]);

    const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
        setselectedTab(newValue);
        if (newValue === HOME_TABS[1].value) {
            navigate(
                `/?req=${encodeURIComponent(
                    JSON.stringify({
                        pageSize: 50,
                    }),
                )}`,
            );
        } else if (newValue === HOME_TABS[0].value) navigate('/');
    }, []);

    const getData = useCallback(
        (data: any) => {
            ctxt?.setLoaderIsActive(true);
            Promise.all([
                getAlbum(data),
                getAlbumAssets({
                    albumId: id ?? '',
                    pageSize: PAGE_SIZE,
                    tags: data.tags,
                    freeText: data.freeText,
                }),
            ])
                .then((res) => {
                    setData({ ...res[0].data, ...res[1].data });
                    setIsFavouriteAlbum(res[0].data.isFavouriteAlbum);
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => ctxt?.setLoaderIsActive(false));
        },
        [id],
    );

    const getMoreAlbumAssets = useCallback(() => {
        const params = searchParams.get('req');
        if (!data) return;
        try {
            const parsedParams = params ? JSON.parse(params) : {};
            setGetMoreAlbumAssetsLoading(true);
            getAlbumAssets({
                albumId: id ?? '',
                tags: parsedParams.tags,
                pageSize: PAGE_SIZE,
                nextPaginationInfo: data.nextPaginationInfo,
            })
                .then((res) => {
                    if (data.elements) res.data.elements = [...data.elements, ...res.data.elements];
                    setData((prev) => ({ ...prev, ...res.data }));
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => setGetMoreAlbumAssetsLoading(false));
        } catch (err) {
            console.error(err);
        }
    }, [searchParams, data, ctxt]);

    const getSortedAssets = useCallback(
        (sortOrder: string | null, sortField: string | null) => {
            const params = searchParams.get('req');
            try {
                const parsedParams = params ? JSON.parse(params) : {};
                ctxt?.setLoaderIsActive(true);
                getAlbumAssets({
                    albumId: id ?? '',
                    tags: parsedParams.tags,
                    pageSize: PAGE_SIZE,
                    freeText: parsedParams.freeText,
                    sortOrder,
                    sortField,
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setData((prev) => ({ ...prev, ...res.data }));
                        }
                    })
                    .catch((err) => {
                        notifyAxiosError(err);
                    })
                    .finally(() => ctxt?.setLoaderIsActive(false));
            } catch (err) {
                console.error(err);
            }
        },
        [searchParams],
    );

    const getQuery = useCallback(() => {
        const params = searchParams.get('req');
        try {
            const parsedParams = params ? JSON.parse(params) : {};
            return {
                albumId: id ?? '',
                tags: parsedParams.tags,
                pageSize: PAGE_SIZE,
                freeText: parsedParams.freeText,
            };
        } catch (err) {
            console.error(err);
        }

        return {
            albumId: id ?? '',
            pageSize: PAGE_SIZE,
        };
    }, [searchParams]);

    const toggleSelected = useCallback((id: string, isAdd: boolean) => {
        if (isAdd) {
            setSelectedAssets((prev) => [...prev, id]);
        } else {
            setSelectedAssets((prev) => prev.filter((item) => item !== id));
        }
    }, []);

    const onFavouriteClick = async () => {
        if (isFavouriteAlbum) {
            sendGoogleAnalyticsEvent('album_favourite_added_' + id);
            await removeAlbumFromFavouritesApi({ albumId: id });
        } else {
            sendGoogleAnalyticsEvent('album_favourite_removed_' + id);
            await addAlbumToFavouritesApi({ albumId: id });
        }
        setIsFavouriteAlbum(!isFavouriteAlbum);
    };

    return (
        <>
            {previewedAsset && (
                <AssetPreview
                    previewedAsset={previewedAsset}
                    onClose={() => setPreviewedAsset(null)}
                    assets={data?.elements || []}
                    toggleSelected={toggleSelected}
                    setIsDownloadAssetModalOpen={setIsDownloadAssetModalOpen}
                />
            )}
            {id && (
                <DeleteAlbumModal
                    isModalOpen={isDeleteAlbumModalOpen}
                    onDismiss={(success) => {
                        setIsDeleteAlbumModalOpen(false);
                        if (success) {
                            navigate('/albums');
                        }
                    }}
                    albumId={id}
                />
            )}
            <DownloadAssetsModal
                onDismiss={() => setIsDownloadAssetModalOpen(false)}
                isOpen={isDownloadAssetModalOpen}
                assetsIds={
                    data?.elements
                        ?.filter(
                            (e) =>
                                selectedAssets.includes(e.id) &&
                                (e.resourceType === 'image' || e.resourceType === 'video'),
                        )
                        .map((sa) => sa.id) ?? []
                }
                imagesAreSelected={
                    !!data?.elements?.find(
                        (e) => selectedAssets.includes(e.id) && e.resourceType === 'image',
                    ) || selectedAssets.length === 0
                }
                videosAreSelected={
                    !!data?.elements?.find(
                        (e) => selectedAssets.includes(e.id) && e.resourceType === 'video',
                    ) || selectedAssets.length === 0
                }
                albumQuery={selectedAssets.length > 0 ? undefined : getQuery()}
                totalResults={selectedAssets.length > 0 ? undefined : data?.totalResults}
            />
            <MassiveDownloadModal
                isOpen={isMassiveDownloadAssetModalOpen}
                onDismiss={() => setIsMassiveDownloadModalOpen(false)}
                assetIds={
                    data?.elements
                        ?.filter(
                            (e) =>
                                selectedAssets.includes(e.id) &&
                                (e.resourceType === 'image' || e.resourceType === 'video'),
                        )
                        .map((sa) => sa.id) ?? []
                }
                albumQuery={selectedAssets.length > 0 ? undefined : getQuery()}
            />
            {isAuthenticated && (
                <Tabs selectedTab={selectedTab} handleChange={handleTabChange} tabs={HOME_TABS} />
            )}
            {data && (
                <div
                    className="px-[16px] md:px-[32px] py-[12.5px] md:py-[25px]"
                    onClick={() => setFilterModalOpen(false)}
                >
                    <div className="flex gap-2 items-center md:mb-[10px]">
                        {isAuthenticated && (
                            <BackArrow
                                className="w-4 h-4 cursor-pointer"
                                onClick={() => navigate('/albums')}
                            />
                        )}
                        <EditAlbumName
                            albumId={id ?? ''}
                            name={data.name}
                            onSuccess={(newName) => {
                                setData({ ...data, name: newName });
                            }}
                            showEditButton={data?.availableActions?.renameAlbum}
                        />

                        <div className="grow"></div>
                        {data?.availableActions?.deleteAlbum === true && (
                            <div
                                className="cursor-pointer"
                                onClick={() => setIsDeleteAlbumModalOpen(true)}
                            >
                                DELETE ALBUM
                            </div>
                        )}
                    </div>
                    <div className="py-6">
                        <div className="font-bold text-gray-09 text-sm">Share Link</div>
                        <div className="flex flex-row gap-2 item-center">
                            <div className="flex items-center">
                                <div>{shareLink}</div>
                            </div>
                            <IconButton
                                iconProps={{
                                    iconName: 'Copy',
                                }}
                                title={'Copy'}
                                onClick={() => {
                                    copy(shareLink);
                                    toast.success('Link copied into clipboard');
                                }}
                            />
                            {!data.isOwnAlbum && (
                                <IconButton
                                    iconProps={{
                                        iconName: isFavouriteAlbum
                                            ? 'FavoriteStarFill'
                                            : 'FavoriteStar',
                                    }}
                                    title={
                                        isFavouriteAlbum
                                            ? 'Remove from favorite'
                                            : 'Add to favorite'
                                    }
                                    disabled={
                                        addAlbumToFavouritesApiResult.isLoading ||
                                        removeAlbumFromFavouritesApiResult.isLoading
                                    }
                                    onClick={() => onFavouriteClick()}
                                />
                            )}
                        </div>
                    </div>
                    <Filter
                        isFilterModalOpen={isFilterModalOpen}
                        setFilterModalOpen={setFilterModalOpen}
                        albumId={id ?? ''}
                    />
                    <Operations
                        albumId={id ?? ''}
                        data={data}
                        hideTags={hideTags}
                        isBigImg={isBigImg}
                        setBigImg={setBigImg}
                        setHideTags={setHideTags}
                        selectedAssets={selectedAssets}
                        getSortedAssets={getSortedAssets}
                        setSelectedAssets={setSelectedAssets}
                        setFilterModalOpen={setFilterModalOpen}
                        setIsDownloadAssetModalOpen={setIsDownloadAssetModalOpen}
                        setIsMassiveDownloadAssetModalOpen={setIsMassiveDownloadModalOpen}
                    />
                    <InfiniteScroll
                        dataLength={data?.elements?.length || 0} //This is important field to render the next data
                        next={getMoreAlbumAssets}
                        hasMore={!!data.hasMore}
                        loader={
                            <div className="mt-4 flex gap-x-2 justify-center items-center bg-gray-04 w-fit text-white mx-auto rounded px-6 py-2">
                                <CircularProgress size={15} color="inherit" />
                                Loading...
                            </div>
                        }
                    >
                        <div
                            className={`grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-4 2xl:grid-cols-4 4xl:grid-cols-6 gap-6 ${
                                !isBigImg && 'md:grid-cols-3'
                            }`}
                        >
                            {data.elements &&
                                data.elements.map((asset: any) => (
                                    <Asset
                                        key={asset.id}
                                        element={asset}
                                        isBigImg={isBigImg}
                                        hideTags={hideTags}
                                        selectedAssets={selectedAssets}
                                        toggleSelected={toggleSelected}
                                        setPreviewedAsset={setPreviewedAsset}
                                        allAssetsAreSelected={selectAllAlbumAssets}
                                        setIsDownloadAssetModalOpen={setIsDownloadAssetModalOpen}
                                    />
                                ))}
                        </div>
                    </InfiniteScroll>
                    {!getMoreAlbumAssetsLoading && data?.elements && !data.hasMore && (
                        <div className="flex justify-center my-6 text-gray-04">
                            {data?.elements.length === 0 ? 'No Result Found' : 'No More Data '}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AlbumDetail;
