import { Tab, Tabs as MUITabs } from '@mui/material';
import React from 'react';

interface IProps {
    selectedTab: string;
    handleChange: (event: React.SyntheticEvent, newValue: string) => void;
    tabs: { label: string; value: string }[];
}

const Tabs: React.FC<IProps> = ({ selectedTab, handleChange, tabs }) => {
    return (
        <MUITabs
            value={selectedTab}
            onChange={handleChange}
            variant={'fullWidth'}
            aria-label="secondary tabs example"
            TabIndicatorProps={{
                style: {
                    backgroundColor: '#161616',
                },
            }}
            sx={{
                minWidth: '100%',
                backgroundColor: 'white',
                position: 'sticky',
                top: 54,
                zIndex: 10000,
            }}
        >
            {tabs.map((tab) => (
                <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    style={{
                        fontFamily: 'Arial',
                        textTransform: 'capitalize',
                        color: '#161616',
                        borderBottom: '2px solid #E0E0E0',
                        fontWeight: tab.value === selectedTab ? 700 : 400,
                    }}
                />
            ))}
        </MUITabs>
    );
};

export default Tabs;
