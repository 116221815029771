import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';

interface IData {
    pageSize: number;
    nextPaginationInfo?: string[];
    freeText?: string;
}

export const getAlbums = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post(`${BaseUrl}Album/GetAlbums`, data, auth);
};
