import { mergeStyleSets, Modal } from '@fluentui/react';
import { CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import DefaultButton from '../../components/DefaultButton';
import PrimaryButton from '../../components/PrimaryButton';
import { MAX_ASSET_ADD_TO_ALBUM } from '../../consts';
import { useDebounce } from '../../hooks/useDebouce';
import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';
import { createAlbum } from '../../services/createAlbum';
import { getAlbums } from '../../services/getAlbums';
import { allApis, disclaimerTag } from '../../store/rtk';
import { formatDate, formatNumber, notifyAxiosError, notifyError } from '../../utils';

interface IProps {
    isModalOpen: boolean;
    selectedAssets: string[];
    setModalState: React.Dispatch<React.SetStateAction<boolean>>;
    query?: any;
    totalResults?: number;
}
const contentStyles = mergeStyleSets({
    container: {
        position: 'relative',
        backGroundColor: 'rgba(0,0,0,0.48)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        overflow: 'auto',
        width: '90%',
        maxWidth: '825px',
        padding: '30px 30px',
    },
    paperFullWidth: {
        overflowY: 'visible',
    },
    dialogContentRoot: {
        overflowY: 'visible',
    },
});

export const ShareModal: React.FC<IProps> = ({
    setModalState,
    isModalOpen,
    selectedAssets,
    query,
    totalResults,
}) => {
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [selectedAlbumId, setSelectedAlbumId] = useState<string>();
    const [selectedTab, setselectedTab] = useState('createNew');
    const [albumsLoading, setAlbumsLoading] = useState(true);
    const newAlbumInputRef = useRef<HTMLInputElement>(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [result, setResult] = useState<{ created: boolean; id: string }>();
    const [data, setData] = useState<any>();

    const dispatch = useDispatch();
    const { data: configurationParameter, isLoading } =
        allApis.useGetDisclaimerGetDisclaimersQuery();
    const disclaimer = configurationParameter?.find(
        (d) => d.disclaimerName === 'UI/DigitalArchive/SharingDisclaimer',
    );

    const [isModalConfirmed, setIsModalConfirmed] = useState(false);

    useEffect(() => {
        setResult(undefined);
        setErrors((prev) => (Object.keys(prev).length === 0 ? prev : {}));
    }, [isModalOpen]);

    useEffect(() => {
        if (selectedTab === 'addToExist') {
            setSelectedAlbumId(undefined);
            getData({
                pageSize: 10,
            });
        }
    }, [selectedTab]);

    const onSave = useCallback(() => {
        setSaveLoading(true);
        if (selectedTab === 'createNew') {
            const inputValue = newAlbumInputRef.current?.value;
            if (!inputValue) {
                setErrors((prev) => ({ ...prev, albumName: true }));
                setSaveLoading(false);
                return;
            }
            createAlbum({
                name: inputValue,
                assetIds: selectedAssets,
                searchQuery: query,
                disclaimerAccepted: !disclaimer?.alreadyAccepted,
            })
                .then((res) => {
                    if (res.data && res.data.created) setResult(res.data);
                    else notifyError('Failed to create album!');
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => {
                    setSaveLoading(false);
                    if (isModalConfirmed) {
                        dispatch(allApis.util.invalidateTags([disclaimerTag]));
                    }
                });
        } else {
            if (!selectedAlbumId) {
                setErrors((prev) => ({ ...prev, selectedAlbum: true }));
                setSaveLoading(false);
                return;
            }
            createAlbum({
                existingAlbumId: selectedAlbumId,
                assetIds: selectedAssets,
                searchQuery: query,
                disclaimerAccepted: !disclaimer?.alreadyAccepted,
            })
                .then((res) => {
                    if (res.data && res.data.created) setResult(res.data);
                    else notifyError('Failed to add assets to existing album!');
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => {
                    setSaveLoading(false);
                    if (isModalConfirmed) {
                        dispatch(allApis.util.invalidateTags([disclaimerTag]));
                    }
                });
        }
    }, [selectedTab, newAlbumInputRef, selectedAssets, selectedAlbumId, isModalConfirmed, query]);

    const onAlbumSearch = useDebounce(
        useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            getData({ pageSize: 10, freeText: e.target.value });
        }, []),
        500,
    );

    const getData = useCallback((data: any) => {
        setAlbumsLoading(true);
        getAlbums(data)
            .then((res) => setData(res.data))
            .catch((err) => {
                notifyAxiosError(err);
            })
            .finally(() => setAlbumsLoading(false));
    }, []);

    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={() => setModalState(false)}
            containerClassName={contentStyles.container}
        >
            {!result ? (
                <div className="px-4">
                    <div className="flex justify-between items-center flex-wrap mb-2">
                        <div>
                            <h2 className="text-[33px] leading-[45px] text-black">
                                Share to album
                            </h2>
                            <div className="text-xs text-[#857A7A]">
                                Only the first {formatNumber(MAX_ASSET_ADD_TO_ALBUM)} selected
                                assets will be added to album
                            </div>
                        </div>
                        <p className="text-sm text-[#857A7A]">
                            {totalResults ? totalResults : selectedAssets.length} assets selected
                        </p>
                    </div>
                    <div className="flex font-semibold text-sm text-[#B0B0B0] w-full border-b border-b-[#DDD9D9] mb-4">
                        <p
                            className="p-2 md:p-4 cursor-pointer duration-100"
                            style={{
                                borderBottom:
                                    selectedTab === 'createNew'
                                        ? '1px solid black'
                                        : '1px solid transparent',
                                color: selectedTab === 'createNew' ? 'black' : '',
                            }}
                            onClick={() => setselectedTab('createNew')}
                        >
                            Create a new album
                        </p>
                        <p
                            className="p-2 md:p-4 cursor-pointer duration-100"
                            style={{
                                borderBottom:
                                    selectedTab === 'addToExist'
                                        ? '1px solid black'
                                        : '1px solid transparent',
                                color: selectedTab === 'addToExist' ? 'black' : '',
                            }}
                            onClick={() => setselectedTab('addToExist')}
                        >
                            Add to existing album
                        </p>
                    </div>
                    <div className="flex flex-col gap-y-3">
                        {selectedTab === 'createNew' && (
                            <>
                                <p className="font-semibold text-sm">Album name</p>
                                <input
                                    ref={newAlbumInputRef}
                                    type="text"
                                    onChange={() =>
                                        setErrors((prev) => ({ ...prev, albumName: false }))
                                    }
                                    placeholder="Type album name"
                                    className="w-full border border-[#EDEDED] outline-none px-4 py-2 hover:border-gray-03 text-sm focus:hover:border-gray-05 focus:border-gray-05"
                                />
                                {errors.albumName && (
                                    <p className="min-w-[1px] min-h-[20px] font-bold text-red-600 text-sm -mt-2">
                                        {errors.albumName && 'Album name is required.'}
                                    </p>
                                )}
                            </>
                        )}
                        {selectedTab === 'addToExist' && (
                            <>
                                <p className="font-semibold text-sm">Search album by name</p>
                                <input
                                    type="text"
                                    placeholder="Type album name"
                                    onChange={onAlbumSearch}
                                    className="border border-[#EDEDED] outline-none px-4 py-2 hover:border-gray-03 text-sm focus:hover:border-gray-05 focus:border-gray-05 mb-3"
                                />
                                <div className="h-[200px]">
                                    <div className="mb-3 font-semibold text-sm">
                                        or select above:
                                    </div>
                                    {albumsLoading ? (
                                        <CircularProgress
                                            color="inherit"
                                            className="my-4 mb-6 mx-auto"
                                        />
                                    ) : (
                                        <div className="max-h-[200px] overflow-auto">
                                            {data && data.elements && data.elements.length > 0 && (
                                                <>
                                                    <table className="w-full">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Album name</th>
                                                                <th>Modified by</th>
                                                                <th>Modified on</th>
                                                                <th>Nr. of asset</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* filtro solo su album fullControl */}
                                                            {data.elements
                                                                .filter(
                                                                    (x: any) =>
                                                                        x.isFavouriteAlbum ===
                                                                        false,
                                                                )
                                                                .map((e: any) => (
                                                                    <tr
                                                                        key={e.id}
                                                                        onClick={() => {
                                                                            setSelectedAlbumId(
                                                                                e.id,
                                                                            );
                                                                            setErrors((prev) => ({
                                                                                ...prev,
                                                                                selectedAlbum:
                                                                                    false,
                                                                            }));
                                                                        }}
                                                                        className="cursor-pointer"
                                                                    >
                                                                        <td>
                                                                            <div
                                                                                className={`my-3 w-[15px] h-[15px] border-[1.5px] border-gray-06 rounded-[2px] flex justify-center items-center ${
                                                                                    selectedAlbumId ===
                                                                                        e.id &&
                                                                                    'bg-gray-06'
                                                                                } cursor-pointer`}
                                                                            >
                                                                                <CheckIcon fill="#fff" />
                                                                            </div>
                                                                        </td>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.modifiedBy}</td>
                                                                        <td>
                                                                            {formatDate(
                                                                                e.lastUpdate,
                                                                            )}
                                                                        </td>
                                                                        <td>{e.assetNumber}</td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </>
                                            )}
                                            {data &&
                                                data.elements &&
                                                data.elements.length === 0 && (
                                                    <p className="text-gray-07 font-bold text-center leading-10">
                                                        Album Not Found!
                                                    </p>
                                                )}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {errors.selectedAlbum && (
                            <p className="min-w-[1px] min-h-[20px] font-bold text-red-600 text-sm -mt-2">
                                {errors.selectedAlbum && 'Selecting album is required.'}
                            </p>
                        )}
                        {!disclaimer?.alreadyAccepted && !isLoading && (
                            <div className="py-4 space-y-4 mt-4">
                                <CheckboxWithLabel
                                    value={isModalConfirmed}
                                    label={disclaimer?.disclaimerText ?? ''}
                                    onToggle={() => setIsModalConfirmed(!isModalConfirmed)}
                                />
                            </div>
                        )}
                        <div className="flex justify-end gap-x-5 mt-1 md:mt-4">
                            <DefaultButton
                                disabled={saveLoading}
                                onClick={() => setModalState(false)}
                            >
                                Cancel
                            </DefaultButton>
                            <PrimaryButton
                                onClick={onSave}
                                disabled={
                                    saveLoading ||
                                    (!isModalConfirmed && !disclaimer?.alreadyAccepted)
                                }
                                isLoading={saveLoading}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <h2 className="text-[33px] text-center leading-10 mb-9">Shared to album</h2>
                    <p className="text-center text-sm mb-9">
                        {totalResults ? totalResults : selectedAssets.length} Assets successfully
                        added to album.
                    </p>
                    <div className="flex justify-center gap-x-5 mt-4">
                        <DefaultButton onClick={() => setModalState(false)}>Cancel</DefaultButton>
                        <Link to={`/albums/${result.id}`}>
                            <PrimaryButton>Go to album</PrimaryButton>
                        </Link>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ShareModal;
