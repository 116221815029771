import React, { createContext, useState } from 'react';

import { HOME_TABS } from '../consts';
import { sendGoogleAnalyticsEvent } from '../utils/extendedGlobal';

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const CommandDataContext = createContext<{
    selectAllAssets: boolean;
    setSelectAllAssets?: (value: boolean) => void;
    selectAllAlbumAssets: boolean;
    setSelectAllAlbumAssets?: (value: boolean) => void;
    selectedTab: string;
    setSelectedTab?: (value: string) => void;
    highlightPeople: boolean;
    setHighlightPeople?: (value: boolean) => void;
}>({
    selectAllAssets: false,
    selectAllAlbumAssets: false,
    selectedTab: HOME_TABS[0].value,
    highlightPeople: true,
});

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function CommandDataProvider({ children }: { children: any }) {
    const [selectAllAssets, setSelectAllAssets] = useState(false);
    const [selectAllAlbumAssets, setSelectAllAlbumAssets] = useState(false);
    const [selectedTab, setSelectedTabInternal] = useState<string>(HOME_TABS[0].value);
    const [highlightPeople, setHighlightPeople] = useState(true);

    const setSelectedTab = (value: string) => {
        sendGoogleAnalyticsEvent(value + '_visited');
        setSelectedTabInternal(value);
    };

    return (
        <CommandDataContext.Provider
            value={{
                selectAllAssets: selectAllAssets,
                setSelectAllAssets: setSelectAllAssets,
                selectAllAlbumAssets: selectAllAlbumAssets,
                setSelectAllAlbumAssets: setSelectAllAlbumAssets,
                selectedTab: selectedTab,
                setSelectedTab: setSelectedTab,
                highlightPeople: highlightPeople,
                setHighlightPeople: setHighlightPeople,
            }}
        >
            {children}
        </CommandDataContext.Provider>
    );
}

export { CommandDataContext, CommandDataProvider };
