import { useEffect, useRef } from 'react';

const useDebounce = (fn: (args?: any) => void, debounceTime: number) => {
    const timeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);

    const deboucedFn = (args?: any) => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            fn(args);
        }, debounceTime);
    };

    return deboucedFn;
};

export { useDebounce };
