import 'yet-another-react-lightbox/styles.css';

import { useIsAuthenticated } from '@azure/msal-react';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import { CommandDataContext } from '../../context/CommandDataContext';
import { useKeyPress } from '../../hooks/keyPress';
import { useDebounce } from '../../hooks/useDebouce';
import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';
import { ReactComponent as UpIcon } from '../../images/icons/up.svg';
import { ReactComponent as ZoomInIcon } from '../../images/icons/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from '../../images/icons/zoom-out.svg';
import { getFolder } from '../../services/getFolder';
import { allApis } from '../../store/rtk';
import { IFolder, ITag } from '../../types';
import {
    downlaodByUrl,
    formatDate,
    getAssetDownloadUrl,
    getRawDownloadUrl,
    notifyAxiosError,
} from '../../utils';
import CheckboxWithLabel from '../CheckboxWithLabel';
import AssetVideo from '../video';
import CanvasImg from './CanvasImg';

interface IProps {
    previewedAsset: any;
    assets: any[];
    onClose: () => void;
    toggleSelected: (id: string, isAdd: boolean) => void;
    setIsDownloadAssetModalOpen: (open: boolean) => void;
}

let cancelToken: any;

const AssetPreview: React.FC<IProps> = ({
    previewedAsset,
    assets,
    onClose,
    setIsDownloadAssetModalOpen,
    toggleSelected,
}) => {
    const arrowUpClick = useKeyPress('ArrowUp');
    const arrowDownClick = useKeyPress('ArrowDown');
    const [folderData, setFolderData] = useState<IFolder>();
    const [isInfoOpen, setInfoOpen] = useState(false);
    const [asset, setAsset] = useState(previewedAsset);
    const [zoomLevel, setZoomLevel] = useState(0);

    const [getAzureUrlApiTrigger] = allApis.useLazyPostSmartBrowsingGetAzureUrlQuery();

    const isAuthenticated = useIsAuthenticated();

    const { highlightPeople, setHighlightPeople } = useContext(CommandDataContext);

    const getFolderData = useDebounce(
        useCallback(() => {
            setFolderData(undefined);
            if (isAuthenticated && asset) {
                const canceledMessage = 'Operation canceled due to new request.';
                if (typeof cancelToken != typeof undefined) {
                    cancelToken.cancel(canceledMessage);
                }
                cancelToken = axios.CancelToken.source();
                getFolder({ folderId: asset.folderId }, cancelToken)
                    .then((res) => setFolderData(res.data))
                    .catch((err) =>
                        err.message === canceledMessage
                            ? console.error(err.message)
                            : notifyAxiosError(err),
                    );
            }
        }, [asset, isAuthenticated]),
        500,
    );

    useEffect(() => {
        if (isInfoOpen && arrowDownClick) setInfoOpen(false);
    }, [arrowDownClick]);

    useEffect(() => {
        if (!isInfoOpen && arrowUpClick) setInfoOpen(true);
    }, [arrowUpClick]);

    const downloadClick = async () => {
        if (!asset) return;

        if (
            asset.resourceType == 'image' ||
            (asset.resourceType == 'video' && asset.storageTypeId != 'AZ')
        ) {
            downlaodByUrl(asset.file.fullName, getAssetDownloadUrl(asset) ?? '');
        } else if (asset.resourceType == 'raw' || asset.storageTypeId == 'AZ') {
            try {
                const azureUrl = await getAzureUrlApiTrigger({
                    assetId: asset.id,
                    storageAccountContainerName: asset.azureStorage.containerName,
                }).unwrap();
                getRawDownloadUrl(asset.file.fullName, azureUrl?.url ?? '');
            } catch (err) {
                console.log(err);
            }
        }
    };

    const getAssetTag = (tagVlues: any[], tagBannedValues: any[]) => {
        interface tagList {
            value: string;
            isBanned: boolean;
        }
        let assetTagList: tagList[] = tagVlues.map((x: any) => ({ value: x, isBanned: false }));
        assetTagList = assetTagList.concat(
            tagBannedValues.map((x: any) => ({ value: x, isBanned: true })),
        );
        return assetTagList;
    };

    if (!assets || assets.length === 0 || !asset) return null;
    const assetFoundIndex = assets.findIndex((a) => a.id === asset.id);
    if (assetFoundIndex === -1) return null;

    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-gray-1/95 z-[99999] ">
            <h2 className="absolute top-5 left-4 text-white z-30">{asset.file?.fullName}</h2>
            <div className="relative w-full h-full">
                <Lightbox
                    open
                    close={onClose}
                    index={assets
                        .filter((asset) => asset.resourceType !== 'raw')
                        .findIndex((item) => item.id === asset.id)}
                    plugins={[Slideshow, Zoom]}
                    slideshow={{ delay: 4000 }}
                    styles={{
                        container: {
                            paddingTop: 6,
                            backgroundColor: 'transparent',
                            zIndex: 999999,
                            overflow: 'visible!important',
                        },
                        root: {
                            zIndex: 999999,
                        },
                    }}
                    on={{
                        view: (prop) => {
                            setZoomLevel(0);
                            setAsset(
                                assets.filter((asset) => asset.resourceType !== 'raw')[prop.index],
                            );
                            getFolderData();
                        },
                    }}
                    slides={assets
                        .filter((asset) => asset.resourceType !== 'raw')
                        .map((asset) => {
                            return {
                                asset: asset,
                            } as any;
                        })}
                    render={{
                        buttonZoom: () => (
                            <div className="flex gap-x-1">
                                <button
                                    disabled={zoomLevel === 3}
                                    onClick={() => setZoomLevel((prev) => prev + 1)}
                                    className="disabled:opacity-40 p-2 hover:bg-gray-08/40 duration-100 disabled:hover:bg-transparent"
                                >
                                    <ZoomInIcon fill="#fff" />
                                </button>
                                <button
                                    disabled={zoomLevel === 0}
                                    onClick={() => setZoomLevel((prev) => prev - 1)}
                                    className="disabled:opacity-40 p-2 hover:bg-gray-08/40 duration-100 disabled:hover:bg-transparent"
                                >
                                    <ZoomOutIcon fill="#fff" />
                                </button>
                            </div>
                        ),
                        slide: ({ slide }: any) => (
                            <>
                                {slide?.asset?.resourceType === 'image' && (
                                    <div className="grid justify-center removescrollbar h-full w-full">
                                        <CanvasImg
                                            asset={slide?.asset}
                                            zoom={zoomLevel}
                                            highlightPeople={highlightPeople}
                                        />
                                    </div>
                                )}
                                {slide?.asset?.resourceType === 'video' && (
                                    <div className="flex justify-center w-full h-full max-h-[calc(100vh-160px)] ">
                                        <AssetVideo
                                            storageTypeId={slide?.asset?.storageTypeId}
                                            extension={slide?.asset?.file?.extension}
                                            cloudinary={slide?.asset?.cloudinary}
                                        />
                                    </div>
                                )}
                            </>
                        ),
                        slideFooter: ({ slide }: any) => (
                            <div
                                className={`absolute bottom-0 w-full max-h-screen pb-[70px] pt-[50px] px-[70px] text-white bg-gray-1/80 customscrollbar overflow-y-auto z-20 duration-150 ${
                                    isInfoOpen ? 'translate-y-0' : 'translate-y-[calc(100%-64px)]'
                                }`}
                            >
                                <div className="ml-auto flex justify-between">
                                    <div className="-translate-y-8">
                                        <CheckboxWithLabel
                                            label="Highlight people"
                                            value={highlightPeople}
                                            onToggle={() => {
                                                setHighlightPeople?.(!highlightPeople);
                                            }}
                                            textStyle={{
                                                userSelect: 'none',
                                                fontSize: '14px',
                                                color: '#FFFFFF',
                                            }}
                                            checkIconFillColor="#191919"
                                        />
                                    </div>
                                    <div className="flex justify-end -translate-y-8">
                                        <DownloadIcon
                                            className="hover:scale-110 rounded-full duration-100 mr-6 cursor-pointer"
                                            fill="#fff"
                                            onClick={() => downloadClick()}
                                        />
                                        <button
                                            onClick={() => setInfoOpen((prev) => !prev)}
                                            className="flex items-center w-[100px] justify-between"
                                        >
                                            {isInfoOpen ? 'Hide ' : 'Show '}info
                                            <UpIcon
                                                className={`duration-150 ${
                                                    isInfoOpen ? 'rotate-180' : 'rotate-0'
                                                }`}
                                            />
                                        </button>
                                    </div>
                                </div>
                                <p className="text-gray-06 font-semibold">Name</p>
                                <p className="mb-4 max-w-md">{slide.asset?.file?.fullName}</p>
                                <p className="text-gray-06 font-semibold">Uploaded</p>
                                <p className="mb-4">
                                    {formatDate(slide.asset?.azureStorage?.uploadedOn)}
                                </p>
                                {isAuthenticated && (
                                    <>
                                        <p className="text-gray-06 font-semibold">Folder</p>
                                        <p className="mb-4">
                                            {folderData?.path || slide?.asset?.folderPath}
                                        </p>
                                    </>
                                )}
                                <p className="text-gray-06 font-semibold">Tags</p>
                                <div className="mb-4">
                                    {slide?.asset?.tags?.map((tag: ITag, idx: number) => (
                                        <div className="inline-block mr-3" key={idx}>
                                            <p className="inline-block capitalize text-gray-04">
                                                {tag?.category}:
                                            </p>
                                            <p className="inline-block ml-1">
                                                {getAssetTag(
                                                    tag?.value ?? [],
                                                    tag?.bannedValues ?? [],
                                                ).map((t, i) => (
                                                    <>
                                                        <span
                                                            key={i}
                                                            className={
                                                                t.isBanned ? 'line-through' : ''
                                                            }
                                                        >
                                                            {t.value}
                                                        </span>
                                                        <span>
                                                            {i <
                                                            getAssetTag(
                                                                tag?.value ?? [],
                                                                tag?.bannedValues ?? [],
                                                            ).length -
                                                                1
                                                                ? ', '
                                                                : ''}
                                                        </span>
                                                    </>
                                                ))}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default AssetPreview;
