import { emptyApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        postAlbumCreateAlbum: build.mutation<
            PostAlbumCreateAlbumApiResponse,
            PostAlbumCreateAlbumApiArg
        >({
            query: (queryArg) => ({ url: `/Album/CreateAlbum`, method: 'POST', body: queryArg }),
        }),
        postAlbumGetAlbums: build.query<PostAlbumGetAlbumsApiResponse, PostAlbumGetAlbumsApiArg>({
            query: (queryArg) => ({ url: `/Album/GetAlbums`, method: 'POST', body: queryArg }),
        }),
        postAlbumGetAlbum: build.query<PostAlbumGetAlbumApiResponse, PostAlbumGetAlbumApiArg>({
            query: (queryArg) => ({ url: `/Album/GetAlbum`, method: 'POST', body: queryArg }),
        }),
        postAlbumGetAlbumAssets: build.query<
            PostAlbumGetAlbumAssetsApiResponse,
            PostAlbumGetAlbumAssetsApiArg
        >({
            query: (queryArg) => ({ url: `/Album/GetAlbumAssets`, method: 'POST', body: queryArg }),
        }),
        postAlbumGetAlbumFilters: build.query<
            PostAlbumGetAlbumFiltersApiResponse,
            PostAlbumGetAlbumFiltersApiArg
        >({
            query: (queryArg) => ({
                url: `/Album/GetAlbumFilters`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postAlbumRemoveAssetFromAlbum: build.mutation<
            PostAlbumRemoveAssetFromAlbumApiResponse,
            PostAlbumRemoveAssetFromAlbumApiArg
        >({
            query: (queryArg) => ({
                url: `/Album/RemoveAssetFromAlbum`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postAlbumDeleteAlbum: build.mutation<
            PostAlbumDeleteAlbumApiResponse,
            PostAlbumDeleteAlbumApiArg
        >({
            query: (queryArg) => ({ url: `/Album/DeleteAlbum`, method: 'POST', body: queryArg }),
        }),
        postAlbumEditAlbum: build.mutation<PostAlbumEditAlbumApiResponse, PostAlbumEditAlbumApiArg>(
            {
                query: (queryArg) => ({ url: `/Album/EditAlbum`, method: 'POST', body: queryArg }),
            },
        ),
        postAlbumEditAlbumCover: build.mutation<
            PostAlbumEditAlbumCoverApiResponse,
            PostAlbumEditAlbumCoverApiArg
        >({
            query: (queryArg) => ({ url: `/Album/EditAlbumCover`, method: 'POST', body: queryArg }),
        }),
        postAlbumGetAlbumFilterValues: build.query<
            PostAlbumGetAlbumFilterValuesApiResponse,
            PostAlbumGetAlbumFilterValuesApiArg
        >({
            query: (queryArg) => ({
                url: `/Album/GetAlbumFilterValues`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postAlbumAddAlbumToFavourites: build.mutation<
            PostAlbumAddAlbumToFavouritesApiResponse,
            PostAlbumAddAlbumToFavouritesApiArg
        >({
            query: (queryArg) => ({
                url: `/Album/AddAlbumToFavourites`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postAlbumRemoveAlbumFromFavourites: build.mutation<
            PostAlbumRemoveAlbumFromFavouritesApiResponse,
            PostAlbumRemoveAlbumFromFavouritesApiArg
        >({
            query: (queryArg) => ({
                url: `/Album/RemoveAlbumFromFavourites`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        getDisclaimerGetDisclaimers: build.query<
            GetDisclaimerGetDisclaimersApiResponse,
            GetDisclaimerGetDisclaimersApiArg
        >({
            query: () => ({ url: `/Disclaimer/GetDisclaimers` }),
        }),
        postDisclaimerInsertDisclaimerLog: build.mutation<
            PostDisclaimerInsertDisclaimerLogApiResponse,
            PostDisclaimerInsertDisclaimerLogApiArg
        >({
            query: (queryArg) => ({
                url: `/Disclaimer/InsertDisclaimerLog`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGetSmartBrowsing: build.query<
            PostSmartBrowsingGetSmartBrowsingApiResponse,
            PostSmartBrowsingGetSmartBrowsingApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GetSmartBrowsing`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGetAssets: build.query<
            PostSmartBrowsingGetAssetsApiResponse,
            PostSmartBrowsingGetAssetsApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GetAssets`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGetFilterValues: build.query<
            PostSmartBrowsingGetFilterValuesApiResponse,
            PostSmartBrowsingGetFilterValuesApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GetFilterValues`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGetFiltersList: build.query<
            PostSmartBrowsingGetFiltersListApiResponse,
            PostSmartBrowsingGetFiltersListApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GetFiltersList`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGenerateArchiveList: build.query<
            PostSmartBrowsingGenerateArchiveListApiResponse,
            PostSmartBrowsingGenerateArchiveListApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GenerateArchiveList`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGetFolder: build.query<
            PostSmartBrowsingGetFolderApiResponse,
            PostSmartBrowsingGetFolderApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GetFolder`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGetAzureUrl: build.query<
            PostSmartBrowsingGetAzureUrlApiResponse,
            PostSmartBrowsingGetAzureUrlApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GetAzureUrl`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingSuggestions: build.query<
            PostSmartBrowsingSuggestionsApiResponse,
            PostSmartBrowsingSuggestionsApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/Suggestions`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        postSmartBrowsingGenerateMassiveDownload: build.query<
            PostSmartBrowsingGenerateMassiveDownloadApiResponse,
            PostSmartBrowsingGenerateMassiveDownloadApiArg
        >({
            query: (queryArg) => ({
                url: `/SmartBrowsing/GenerateMassiveDownload`,
                method: 'POST',
                body: queryArg,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as allApis };
export type PostAlbumCreateAlbumApiResponse = /** status 200 Success */ CreateAlbumResponse;
export type PostAlbumCreateAlbumApiArg = CreateAlbum;
export type PostAlbumGetAlbumsApiResponse = /** status 200 Success */ AlbumPagedResult;
export type PostAlbumGetAlbumsApiArg = GetAlbumsSp;
export type PostAlbumGetAlbumApiResponse = unknown;
export type PostAlbumGetAlbumApiArg = GetAlbumSp;
export type PostAlbumGetAlbumAssetsApiResponse = /** status 200 Success */ AssetsResult;
export type PostAlbumGetAlbumAssetsApiArg = GetAlbumAssetsSp;
export type PostAlbumGetAlbumFiltersApiResponse = /** status 200 Success */ string[];
export type PostAlbumGetAlbumFiltersApiArg = GetAlbumFiltersSp;
export type PostAlbumRemoveAssetFromAlbumApiResponse =
    /** status 200 Success */ RemoveAssetFromAlbumResponse;
export type PostAlbumRemoveAssetFromAlbumApiArg = RemoveAssetFromAlbum;
export type PostAlbumDeleteAlbumApiResponse = /** status 200 Success */ DeleteAlbumResponse;
export type PostAlbumDeleteAlbumApiArg = DeleteAlbum;
export type PostAlbumEditAlbumApiResponse = /** status 200 Success */ EditAlbumResponse;
export type PostAlbumEditAlbumApiArg = EditAlbum;
export type PostAlbumEditAlbumCoverApiResponse = /** status 200 Success */ EditAlbumCoverResponse;
export type PostAlbumEditAlbumCoverApiArg = EditAlbumCover;
export type PostAlbumGetAlbumFilterValuesApiResponse = /** status 200 Success */ FilterValue[];
export type PostAlbumGetAlbumFilterValuesApiArg = GetAlbumFilterValuesSp;
export type PostAlbumAddAlbumToFavouritesApiResponse =
    /** status 200 Success */ AddAlbumToFavouriteResponses;
export type PostAlbumAddAlbumToFavouritesApiArg = AddAlbumToFavourites;
export type PostAlbumRemoveAlbumFromFavouritesApiResponse =
    /** status 200 Success */ RemoveAlbumFromFavouritesResponse;
export type PostAlbumRemoveAlbumFromFavouritesApiArg = RemoveAlbumFromFavourites;
export type GetDisclaimerGetDisclaimersApiResponse = /** status 200 Success */ DisclaimerObject[];
export type GetDisclaimerGetDisclaimersApiArg = void;
export type PostDisclaimerInsertDisclaimerLogApiResponse =
    /** status 200 Success */ InsertDisclaimerLogResponse;
export type PostDisclaimerInsertDisclaimerLogApiArg = InsertDisclaimerLogRequest;
export type PostSmartBrowsingGetSmartBrowsingApiResponse =
    /** status 200 Success */ SmartBrowsingResult;
export type PostSmartBrowsingGetSmartBrowsingApiArg = GetSmartBrowsingSp;
export type PostSmartBrowsingGetAssetsApiResponse = /** status 200 Success */ AssetsResult;
export type PostSmartBrowsingGetAssetsApiArg = GetAssetsSp;
export type PostSmartBrowsingGetFilterValuesApiResponse = /** status 200 Success */ FilterValue[];
export type PostSmartBrowsingGetFilterValuesApiArg = GetFiltersValueSp;
export type PostSmartBrowsingGetFiltersListApiResponse = /** status 200 Success */ string[];
export type PostSmartBrowsingGetFiltersListApiArg = GetFiltersListSp;
export type PostSmartBrowsingGenerateArchiveListApiResponse =
    /** status 200 Success */ GenericDownloadZipResponse;
export type PostSmartBrowsingGenerateArchiveListApiArg = GenericDownloadZipRequest;
export type PostSmartBrowsingGetFolderApiResponse = /** status 200 Success */ Folder;
export type PostSmartBrowsingGetFolderApiArg = GetFolderSp;
export type PostSmartBrowsingGetAzureUrlApiResponse = /** status 200 Success */ AzureUrl;
export type PostSmartBrowsingGetAzureUrlApiArg = GetAzureUrlSp;
export type PostSmartBrowsingSuggestionsApiResponse = /** status 200 Success */ Suggestion;
export type PostSmartBrowsingSuggestionsApiArg = SuggestionSp;
export type PostSmartBrowsingGenerateMassiveDownloadApiResponse =
    /** status 200 Success */ UrlToDownload[];
export type PostSmartBrowsingGenerateMassiveDownloadApiArg = MassiveDownloadRequest;
export type CreateAlbumResponse = {
    created?: boolean;
    id?: string | null;
};
export type Tag = {
    category?: string | null;
    value?: string[] | null;
    hidden?: boolean;
    readOnly?: boolean;
    bannedValues?: string[] | null;
};
export type GetAssetsSp = {
    sortOrder?: string | null;
    sortField?: string | null;
    pageSize?: number;
    nextPaginationInfo?: any[] | null;
    freeText?: string | null;
    tags?: Tag[] | null;
};
export type CreateAlbum = {
    name?: string | null;
    existingAlbumId?: string | null;
    assetIds?: string[] | null;
    searchQuery?: GetAssetsSp;
    disclaimerAccepted?: boolean | null;
};
export type FileInfo = {
    fullName?: string | null;
    name?: string | null;
    extension?: string | null;
};
export type CloudinaryInfo = {
    publicId?: string | null;
    version?: string | null;
    width?: number | null;
    height?: number | null;
    bytes?: number | null;
    urlPrefix?: string | null;
    uploadedOn?: string | null;
    cloudName?: string | null;
};
export type Image = {
    imageId?: string | null;
    file?: FileInfo;
    cloudinary?: CloudinaryInfo;
    storageTypeId?: string | null;
    resourceType?: string | null;
};
export type AvailableActions = {
    deleteAlbum?: boolean;
    removeAlbumImage?: boolean;
    setAlbumCoverImage?: boolean;
    renameAlbum?: boolean;
};
export type Album = {
    id?: string | null;
    name?: string | null;
    lastUpdate?: string;
    modifiedBy?: string | null;
    assetNumber?: number | null;
    image?: Image;
    availableActions?: AvailableActions;
    isFavouriteAlbum?: boolean;
    isOwnAlbum?: boolean;
};
export type AlbumPagedResult = {
    pageIndex?: number;
    pageSize?: number;
    totalResults?: number;
    totalPages?: number;
    elements?: Album[] | null;
    took?: number;
};
export type GetAlbumsSp = {
    pageIndex?: number;
    pageSize?: number;
    sortExpression?: string | null;
    noCount?: boolean;
    freeText?: string | null;
};
export type GetAlbumSp = {
    id?: string | null;
};
export type Product = {
    code?: string | null;
    description?: string | null;
};
export type Cloudinary = {
    publicId?: string | null;
    version?: string | null;
    format?: string | null;
    width?: number;
    height?: number;
    bytes?: number;
    urlPrefix?: string | null;
    uploadedOn?: string | null;
    cloudName?: string | null;
};
export type AzureStorage = {
    containerName?: string | null;
    uploadedOn?: string | null;
};
export type Audit = {
    createdBy?: string | null;
    createdOn?: string;
    modifiedBy?: string | null;
    modifiedOn?: string;
};
export type Lookup = {
    id?: string | null;
    description?: string | null;
};
export type File = {
    fullName?: string | null;
    name?: string | null;
    extension?: string | null;
};
export type Face = {
    personName?: string | null;
    cornerLeft?: number | null;
    cornerTop?: number | null;
    width?: number | null;
    height?: number | null;
    isIgnored?: boolean;
    isBanned?: boolean;
};
export type Asset = {
    id?: string | null;
    assetType?: string | null;
    resourceType?: string | null;
    folderId?: number;
    folderPath?: string | null;
    colorCode?: string | null;
    alternateName?: string | null;
    region?: string | null;
    originalBytes?: number;
    isPublished?: boolean;
    assetHash?: string | null;
    product?: Product;
    cloudinary?: Cloudinary;
    azureStorage?: AzureStorage;
    audit?: Audit;
    shootingType?: Lookup;
    season?: Lookup;
    folders?: string[] | null;
    file?: File;
    tags?: Tag[] | null;
    storageTypeId?: string | null;
    faces?: Face[] | null;
};
export type ParentsFolders = {
    aggregationId?: string | null;
    title?: string | null;
    tags?: Tag[] | null;
};
export type AssetsResult = {
    totalResults?: number;
    hasMore?: boolean;
    nextPaginationInfo?: any[] | null;
    elements?: Asset[] | null;
    title?: string | null;
    parentsFolders?: ParentsFolders[] | null;
    resultType?: string | null;
};
export type GetAlbumAssetsSp = {
    sortOrder?: string | null;
    sortField?: string | null;
    pageSize?: number;
    nextPaginationInfo?: any[] | null;
    albumId?: string | null;
    freeText?: string | null;
    tags?: Tag[] | null;
};
export type GetAlbumFiltersSp = {
    albumId?: string | null;
    freeText?: string | null;
    tags?: Tag[] | null;
};
export type RemoveAssetFromAlbumResponse = {
    removed?: boolean;
};
export type RemoveAssetFromAlbum = {
    albumId?: string | null;
    assetsId?: string[] | null;
};
export type DeleteAlbumResponse = {
    deleted?: boolean;
};
export type DeleteAlbum = {
    albumId?: string | null;
};
export type EditAlbumResponse = {
    edited?: boolean;
};
export type EditAlbum = {
    albumId?: string | null;
    albumDs?: string | null;
};
export type EditAlbumCoverResponse = {
    albumCoverEdited?: boolean;
};
export type EditAlbumCover = {
    albumId?: string | null;
    imageId?: string | null;
};
export type FilterValue = {
    id?: string | null;
    value?: string | null;
};
export type GetAlbumFilterValuesSp = {
    freeText?: string | null;
    tags?: Tag[] | null;
    field?: string | null;
    filterFreeText?: string | null;
    sortOrder?: string | null;
    sortField?: string | null;
    albumId?: string | null;
};
export type AddAlbumToFavouriteResponses = {
    addAlbumToFavourite?: boolean;
};
export type AddAlbumToFavourites = {
    albumId?: string | null;
};
export type RemoveAlbumFromFavouritesResponse = {
    removedAlbum?: boolean;
};
export type RemoveAlbumFromFavourites = {
    albumId?: string | null;
};
export type DisclaimerQuestion = {
    disclaimerQuestionText?: string | null;
    isRequired?: boolean;
};
export type DisclaimerObject = {
    disclaimerName?: string | null;
    disclaimerText?: string | null;
    checkBoxes?: DisclaimerQuestion[] | null;
    alreadyAccepted?: boolean;
};
export type InsertDisclaimerLogResponse = {
    result?: boolean;
};
export type InsertDisclaimerLogRequest = {
    disclaimerType?: string | null;
};
export type SmartBrowsingItem = {
    title?: string | null;
    totalAssets?: number;
    imageUrl?: string | null;
    tags?: Tag[] | null;
    viewOnlyTags?: Tag[] | null;
    nextAggreationId?: string | null;
};
export type SmartBrowsingResult = {
    title?: string | null;
    smartFolders?: SmartBrowsingItem[] | null;
    parentsFolders?: ParentsFolders[] | null;
    nextPaginationInfo?: any | null;
    hasMore?: boolean;
    resultType?: string | null;
};
export type SortingTag = {
    field?: string | null;
    order?: string | null;
};
export type GetSmartBrowsingSp = {
    freeText?: string | null;
    tags?: Tag[] | null;
    aggregationId?: string | null;
    pageSize?: number | null;
    nextPaginationInfo?: {
        [key: string]: any;
    } | null;
    sortingTags?: SortingTag[] | null;
};
export type GetFiltersValueSp = {
    freeText?: string | null;
    tags?: Tag[] | null;
    field?: string | null;
    filterFreeText?: string | null;
    sortOrder?: string | null;
    sortField?: string | null;
};
export type GetFiltersListSp = {
    freeText?: string | null;
    tags?: Tag[] | null;
    isFreeBrowsing?: boolean | null;
};
export type GenericDownloadAsset = {
    index?: number;
    resourcetype?: string | null;
    url?: string | null;
    description?: string | null;
    totalElements?: number;
};
export type GenericDownloadZipResponse = {
    downloadAssets?: GenericDownloadAsset[] | null;
};
export type GenericDownloadZipRequest = {
    imageWidth?: number | null;
    imageHeight?: number | null;
    imageMode?: string | null;
    imageFormat?: string | null;
    imageQuality?: string | null;
    videoWidth?: number | null;
    videoHeight?: number | null;
    videoMode?: string | null;
    videoFormat?: string | null;
    videoQuality?: string | null;
    assetIds?: string[] | null;
    searchQuery?: GetAssetsSp;
    searchQueryAlbum?: GetAlbumAssetsSp;
    disclaimerAccepted?: boolean | null;
};
export type Folder = {
    folderName?: string | null;
    path?: string | null;
};
export type GetFolderSp = {
    folderId?: number;
};
export type AzureUrl = {
    url?: string | null;
};
export type GetAzureUrlSp = {
    assetId?: string | null;
    storageAccountContainerName?: string | null;
};
export type Suggestion = {
    fileNameValues?: string[] | null;
    productCodeValues?: string[] | null;
    productDescriptionValues?: string[] | null;
    colorCodeValues?: string[] | null;
    tagsValues?: string[] | null;
};
export type SuggestionSp = {
    freeText?: string | null;
};
export type UrlToDownload = {
    assetName?: string | null;
    url?: string | null;
};
export type MassiveDownloadRequest = {
    searchQuery?: GetAssetsSp;
    searchQueryAlbum?: GetAlbumAssetsSp;
    assetIds?: string[] | null;
};
export const {
    usePostAlbumCreateAlbumMutation,
    usePostAlbumGetAlbumsQuery,
    usePostAlbumGetAlbumQuery,
    usePostAlbumGetAlbumAssetsQuery,
    usePostAlbumGetAlbumFiltersQuery,
    usePostAlbumRemoveAssetFromAlbumMutation,
    usePostAlbumDeleteAlbumMutation,
    usePostAlbumEditAlbumMutation,
    usePostAlbumEditAlbumCoverMutation,
    usePostAlbumGetAlbumFilterValuesQuery,
    usePostAlbumAddAlbumToFavouritesMutation,
    usePostAlbumRemoveAlbumFromFavouritesMutation,
    useGetDisclaimerGetDisclaimersQuery,
    usePostDisclaimerInsertDisclaimerLogMutation,
    usePostSmartBrowsingGetSmartBrowsingQuery,
    usePostSmartBrowsingGetAssetsQuery,
    usePostSmartBrowsingGetFilterValuesQuery,
    usePostSmartBrowsingGetFiltersListQuery,
    usePostSmartBrowsingGenerateArchiveListQuery,
    usePostSmartBrowsingGetFolderQuery,
    usePostSmartBrowsingGetAzureUrlQuery,
    usePostSmartBrowsingSuggestionsQuery,
    usePostSmartBrowsingGenerateMassiveDownloadQuery,
} = injectedRtkApi;
