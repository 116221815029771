import React, { useState } from 'react';

import EditField from '../../components/EditField';
import { editAlbum } from '../../services/editAlbum';
import { notifyAxiosError } from '../../utils';

interface IProps {
    albumId: string;
    name: string;
    onSuccess: (newName: string) => void;
    showEditButton?: boolean;
}

export const EditAlbumName: React.FC<IProps> = ({ albumId, name, onSuccess, showEditButton }) => {
    const [isExecutingAction, setIsExecutingAction] = useState(false);

    const onSave = (newValue: string) => {
        if (!newValue) {
            notifyAxiosError({ message: "Value can't be empty" });
            return;
        }
        if (!albumId) {
            notifyAxiosError({ message: "Album Id can't be empty" });
            return;
        }

        setIsExecutingAction(true);
        editAlbum({ albumId: albumId, albumDs: newValue })
            .then(() => onSuccess(newValue))
            .catch((err) => notifyAxiosError(err))
            .finally(() => {
                setIsExecutingAction(false);
            });
    };

    return (
        <EditField
            value={name}
            onSave={onSave}
            loading={isExecutingAction}
            disabled={isExecutingAction}
            placeHolder="New name"
            textClassName="font-bold text-[28px] leading-[32.2px] text-gray-10"
            showEditButton={showEditButton}
        />
    );
};

export default EditAlbumName;
