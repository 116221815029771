import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../images/icons/back-arrow.svg';
import { ISmartBrowsingResponse } from '../../types';
import { encodeURLParam } from '../../utils';

interface IProps {
    data: ISmartBrowsingResponse;
}

const BreadCrumb: React.FC<IProps> = ({ data }) => {
    const navigate = useNavigate();
    return (
        <div className="flex text-gray-06">
            {data.parentsFolders &&
                data.parentsFolders.map((folder, idx) => (
                    <span
                        onClick={() => {
                            const encodedParam = encodeURLParam({
                                aggregationId: folder.aggregationId,
                                tags: folder.tags.map((tag) => ({
                                    value: tag.value,
                                    category: tag.category,
                                    hidden: tag.hidden,
                                    readOnly: tag.readOnly,
                                })),
                            });
                            navigate(`?req=${encodedParam}`);
                        }}
                        key={idx}
                        className="flex items-center gap-x-1 hover:text-gray-1 cursor-pointer"
                    >
                        {`${folder.title}`}
                        <BackArrow className="w-3 h-3 rotate-180" />
                        &nbsp;
                    </span>
                ))}
        </div>
    );
};

export default BreadCrumb;
