import { Popover, Transition } from '@headlessui/react';
import React from 'react';

import { ReactComponent as ClearIcon } from '../images/icons/clear.svg';
import { ReactComponent as DividerIcon } from '../images/icons/divider.svg';
import { ReactComponent as UpArrowIcon } from '../images/icons/up-arrow.svg';

interface IProps {
    selectedSort: { field: string; label: string; order: string } | null;
    setSelectedSort: (newSort: { field: string; label: string; order: 'DESC' | 'ASC' }) => void;
    sortOptions: { field: string; label: string }[];
    onClear: () => void;
}

const SortButton: React.FC<IProps> = ({ selectedSort, sortOptions, setSelectedSort, onClear }) => {
    return (
        <Popover className="relative">
            {({ close }) => (
                <>
                    <Popover.Button className="border-none bg-white pl-[13px] pr-[10px] rounded flex items-center text-sm">
                        Sort by{selectedSort && `: ${selectedSort.label}`}
                        <DividerIcon className="ml-3 mr-2" />
                        <div
                            onClick={(e) => {
                                if (selectedSort) {
                                    e.stopPropagation();
                                    setSelectedSort({
                                        field: selectedSort.field,
                                        label: selectedSort.label,
                                        order: selectedSort.order === 'ASC' ? 'DESC' : 'ASC',
                                    });
                                    close();
                                }
                            }}
                            className="py-[10px] px-1"
                        >
                            <UpArrowIcon
                                className={`w-3 duration-150 ${
                                    selectedSort ? 'fill-gray-07' : 'fill-gray-03'
                                } ${selectedSort?.order === 'DESC' ? 'rotate-0' : 'rotate-180'}`}
                            />
                        </div>
                    </Popover.Button>
                    <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute mt-1 w-full z-50 bg-white flex flex-col shadow text-sm">
                            {sortOptions
                                .sort((a, b) => a.field.localeCompare(b.field))
                                .map(({ field, label }) => (
                                    <p
                                        key={field}
                                        onClick={() => {
                                            setSelectedSort({
                                                field,
                                                label,
                                                order: 'ASC',
                                            });
                                            close();
                                        }}
                                        className={`pl-3 py-1  cursor-pointer duration-150 ${
                                            selectedSort?.field === field
                                                ? 'bg-gray-03'
                                                : 'hover:bg-gray-01'
                                        }`}
                                    >
                                        {label}
                                    </p>
                                ))}
                            <button
                                disabled={!selectedSort}
                                onClick={() => onClear()}
                                className="flex items-center justify-center gap-x-2 py-2 text-gray-07 fill-gray-07 
                                            hover:bg-red-500 hover:fill-white hover:text-white duration-150 disabled:text-gray-03 
                                            disabled:fill-gray-03 disabled:cursor-not-allowed disabled:hover:bg-transparent"
                            >
                                <ClearIcon className="fill-inherit" /> Clear
                            </button>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default SortButton;
