import { Icon, IconButton, ProgressIndicator } from '@fluentui/react';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { allApis } from '../store/rtk';
import { delay } from '../utils';
import { sendGoogleAnalyticsEvent } from '../utils/extendedGlobal';
import { BasicModal } from './BasicModal';
import DefaultButton from './DefaultButton';
import PrimaryButton from './PrimaryButton';

interface IProps {
    isOpen: boolean;
    assetIds: string[];
    query?: any;
    albumQuery?: any;
    onDismiss: () => void;
}

interface AssetsToDownload {
    url: string;
    assetsName: string;
    downloadStarted: boolean;
    downloadFailed: boolean;
}

const ProgressBar = ({
    state,
    progress,
    totalAssets,
    onCancel,
    onPause,
    onResume,
}: {
    progress: number;
    totalAssets: number;
    onCancel: () => void;
    onPause: () => void;
    onResume: () => void;
    state?: 'cancel' | 'downloading' | 'paused';
}) => {
    const getProgressBarColor = () => {
        switch (state) {
            case 'downloading':
                return 'rgb(21 128 61)';
            case 'cancel':
                return 'rgb(185 28 28)';
            case 'paused':
                return 'rgb(84 110 122)';
        }
    };

    const getPercentange = () => {
        switch (state) {
            case 'cancel':
                return 100;
            default:
                return progress / totalAssets;
        }
    };

    return (
        <ProgressIndicator
            label={
                <div className="flex flex-row gap-2 items-center">
                    <div>
                        File downloaded {progress}/{totalAssets}
                    </div>
                    {state === 'downloading' && (
                        <IconButton
                            iconProps={{
                                iconName: 'Pause',
                            }}
                            size={4}
                            onClick={onPause}
                            title="Pause"
                        />
                    )}
                    {state === 'paused' && (
                        <IconButton
                            iconProps={{
                                iconName: 'PlayResume',
                            }}
                            size={4}
                            onClick={onResume}
                            title="Resume"
                        />
                    )}

                    <IconButton
                        iconProps={{
                            iconName: 'Cancel',
                        }}
                        size={4}
                        onClick={onCancel}
                        title="Cancel"
                    />
                </div>
            }
            percentComplete={getPercentange()}
            barHeight={8}
            description={
                <div>
                    {state === 'cancel' && (
                        <div className="flex flex-row items-center gap-2 text-red-700">
                            <span>Process cancelled</span>
                        </div>
                    )}
                    {state === 'downloading' && progress > 0 && totalAssets > 0 && (
                        <div>{((progress / totalAssets) * 100).toFixed()}% uploaded</div>
                    )}
                </div>
            }
            styles={{
                progressBar: {
                    backgroundColor: getProgressBarColor(),
                },
            }}
        />
    );
};

export const MassiveDownloadModal: React.FC<IProps> = ({
    isOpen,
    assetIds,
    query,
    albumQuery,
    onDismiss,
}) => {
    const [assetsToDownload, setAssetsToDownload] = useState<AssetsToDownload[]>([]);
    const [downloadIsStarted, setDownloadIsStarted] = useState(false);
    const [assetsDownloaded, setAssetsDownloaded] = useState(0);
    const [progressState, setProgressState] = useState<'cancel' | 'downloading' | 'paused'>(
        'downloading',
    );
    const cancelDownload = useRef(false);
    const pauseDownlaod = useRef(false);

    const [generateUrl, generateUrlApiCall] =
        allApis.useLazyPostSmartBrowsingGenerateMassiveDownloadQuery();

    const downloadFIleByUrl = async (fileName?: string, url?: string) => {
        try {
            if (!url) return false;

            const response = await fetch(url ?? '');
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName ?? '';
            link.click();

            sendGoogleAnalyticsEvent('download_asset_' + fileName);

            return true;
        } catch (e) {
            toast.error('File ' + fileName + ' failed to download');
            return false;
        }
    };

    const downloadAll = async (assets: AssetsToDownload[]) => {
        try {
            if (assets) {
                setDownloadIsStarted(true);
                for (let index = 0; index < assets?.length ?? 0; index++) {
                    if (pauseDownlaod.current || cancelDownload.current) break;
                    if (assets[index].downloadStarted) continue;
                    const result = await downloadFIleByUrl(
                        assets[index].assetsName,
                        assets[index].url,
                    );
                    const newAssets = assets.slice();

                    if (result) {
                        newAssets[index].downloadStarted = true;
                        setAssetsDownloaded((prev) => prev + 1);
                    } else {
                        newAssets[index].downloadFailed = true;
                    }
                    setAssetsToDownload(newAssets);

                    await delay(1000);
                }
                setDownloadIsStarted(false);
            }
        } catch (e) {}
    };

    const onCancelDownload = () => {
        cancelDownload.current = true;
        setDownloadIsStarted(false);
        setProgressState('cancel');
    };

    const onPauseDownlaod = () => {
        pauseDownlaod.current = true;
        setDownloadIsStarted(false);
        setProgressState('paused');
    };

    const onResume = () => {
        pauseDownlaod.current = false;
        cancelDownload.current = false;
        setDownloadIsStarted(true);
        downloadAll(assetsToDownload);
        setProgressState('downloading');
    };

    const internalDismiss = () => {
        cancelDownload.current = false;
        pauseDownlaod.current = false;
        setProgressState('downloading');

        onDismiss();
    };

    const resetState = () => {
        setAssetsToDownload([]);
        setAssetsDownloaded(0);
        setDownloadIsStarted(false);
        cancelDownload.current = false;
        pauseDownlaod.current = false;
        setProgressState('downloading');
    };

    const getData = async () => {
        try {
            const result = await generateUrl(
                {
                    assetIds: assetIds,
                    searchQuery: query,
                    searchQueryAlbum: albumQuery,
                },
                false,
            ).unwrap();
            const assetsToDownload =
                result?.map<AssetsToDownload>((x) => ({
                    assetsName: x.assetName ?? '',
                    url: x.url ?? '',
                    downloadStarted: false,
                    downloadFailed: false,
                })) ?? [];
            setAssetsToDownload(assetsToDownload);
        } catch (e) {}
    };

    useEffect(() => {
        if (isOpen) {
            resetState();
            getData();
        }
    }, [isOpen]);

    return (
        <BasicModal isModalOpen={isOpen}>
            {!generateUrlApiCall.isFetching ? (
                <div className="px-4">
                    <div className="flex justify-between items-center flex-wrap mb-2">
                        <div>
                            <h2 className="text-[33px] leading-[45px] text-black">
                                Massive assets download
                            </h2>
                            <div className="text-xs text-red-700">
                                <div>Warning:</div>
                                <ol className="list-decimal list-inside">
                                    <li>
                                        If you close or refresh the page, the queue will be reset
                                    </li>
                                    <li>
                                        Once the download has started, to close the pop-up you must
                                        first stop or delete the process
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <p className="text-sm text-[#857A7A]">
                            {assetsToDownload.length} assets selected
                        </p>
                    </div>
                    <div className="flex flex-col gap-y-3 mt-4">
                        <div className="h-[300px] overflow-auto">
                            {assetsToDownload.map((x, i) => (
                                <div key={i} className="flex items-center">
                                    <div className="me-4">
                                        {x.downloadFailed && (
                                            <Icon iconName="Error" className="text-red-700" />
                                        )}
                                        {x.downloadStarted && <Icon iconName="Accept" />}
                                        {!x.downloadStarted && !x.downloadFailed && (
                                            <Icon iconName="ProgressRingDots" />
                                        )}
                                    </div>
                                    <div>{x.assetsName}</div>
                                </div>
                            ))}
                        </div>
                        <ProgressBar
                            progress={assetsDownloaded}
                            totalAssets={assetsToDownload.length}
                            state={progressState}
                            onCancel={onCancelDownload}
                            onPause={onPauseDownlaod}
                            onResume={onResume}
                        />
                        <div className="flex justify-end items-stretch gap-x-5 mt-1 md:mt-4">
                            <DefaultButton
                                disabled={assetsToDownload.length === 0 || downloadIsStarted}
                                onClick={() => internalDismiss()}
                            >
                                Close
                            </DefaultButton>
                            {progressState !== 'cancel' && (
                                <PrimaryButton
                                    onClick={() => onResume()}
                                    disabled={assetsToDownload.length === 0 || downloadIsStarted}
                                    isLoading={downloadIsStarted}
                                >
                                    Start download
                                </PrimaryButton>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex justify-center">
                    <CircularProgress color="inherit" className="my-4 mb-6 mx-auto" />
                </div>
            )}
        </BasicModal>
    );
};
