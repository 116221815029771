import React from 'react';

import { ReactComponent as ShowTagIcon } from '../images/icons/hide.svg';
import { ReactComponent as HideTagIcon } from '../images/icons/show.svg';

interface IProps {
    isBigImg: boolean;
    setBigImg: (newValue: boolean) => void;
}

const SmallBigViewSwitch: React.FC<IProps> = ({ isBigImg, setBigImg }) => {
    return (
        <div className="h-fit p-[5px] bg-gray-02 flex items-center gap-x-[5px] rounded">
            <button
                className={`w-[26px] h-[26px] flex justify-center items-center rounded ${
                    !isBigImg && 'bg-white'
                }`}
                title="Small images"
                onClick={() => setBigImg(false)}
            >
                <ShowTagIcon />
            </button>
            <button
                className={`w-[26px] h-[26px] flex justify-center items-center rounded ${
                    isBigImg && 'bg-white'
                }`}
                title="Big images"
                onClick={() => setBigImg(true)}
            >
                <HideTagIcon />
            </button>
        </div>
    );
};

export default SmallBigViewSwitch;
