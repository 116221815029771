import { Popover } from '@headlessui/react';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';

import { useWindowSize } from '../../hooks/windowSize';
import { ReactComponent as DownIcon } from '../../images/icons/down.svg';
import { ITag } from '../../types';

interface IProps {
    params: any;
    category: string;
    filterValues: { [key: string]: any };
    onFilterChange: (newValue: { [key: string]: any }) => void;
    loadOptions: (inputValue: string, category: string) => void;
    setFilterValues: (newValue: { [key: string]: any }) => void;
}

const FilterSelect: React.FC<IProps> = ({
    params,
    category,
    loadOptions,
    filterValues,
    onFilterChange,
    setFilterValues,
}) => {
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });

    const windowsSize = useWindowSize();
    const isDisabled = useMemo(
        () =>
            !!params?.tags?.find((tag: ITag) => tag.category === category && tag.readOnly) || false,
        [params, category],
    );

    const selectedFilter = Object.keys(filterValues[category] ?? {}).length;
    const filterHasSelectedValues = selectedFilter > 0;

    let filterClasses = 'bg-gray-01 cursor-pointer';
    if (isDisabled) {
        filterClasses = 'bg-gray-05 cursor-not-allowed text-white';
    } else if (!isDisabled) {
        if (filterHasSelectedValues) {
            filterClasses = 'bg-gray-08 text-white';
        } else {
            filterClasses = 'border-transparent text-gray-05 bg-gray-01';
        }
    }

    return (
        <Popover key={category}>
            {({ open }) => (
                <>
                    <Popover.Button
                        ref={setReferenceElement}
                        className="border-none outline-none"
                        disabled={isDisabled}
                    >
                        <div
                            style={{
                                width:
                                    windowsSize.width && windowsSize.width < 767
                                        ? windowsSize.width / 2 - 32
                                        : 'fit-content',
                            }}
                            className={`min-w-fit p-[6px] text-sm capitalize rounded flex items-center border hover:border-gray-03 duration-100 ${filterClasses}`}
                        >
                            <p className="w-fit h-full mx-1">
                                {filterValues[category] && filterValues[category][0] ? (
                                    filterValues[category][0].label.toLowerCase() === 'not set' ? (
                                        filterValues[category].length > 1 ? (
                                            filterValues[category][1].label
                                        ) : (
                                            category + ': Not set'
                                        )
                                    ) : (
                                        filterValues[category][0].label.toUpperCase()
                                    )
                                ) : (
                                    <span className="capitalize">{category}</span>
                                )}
                            </p>
                            {filterValues[category] && selectedFilter > 1 && (
                                <span className="mx-1">+{selectedFilter - 1}</span>
                            )}
                            {!isDisabled && (
                                <span
                                    style={{
                                        transition: '0.2s',
                                        marginLeft: 'auto',
                                        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                    }}
                                >
                                    <DownIcon
                                        className={
                                            filterHasSelectedValues
                                                ? 'fill-white'
                                                : 'fill-[#525252]'
                                        }
                                    />
                                </span>
                            )}
                        </div>
                    </Popover.Button>

                    {ReactDOM.createPortal(
                        <Popover.Panel
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className="z-40"
                        >
                            <AsyncSelect
                                isMulti
                                cacheOptions
                                key={category}
                                defaultOptions
                                menuIsOpen={true}
                                placeholder="Search..."
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                value={filterValues[category] || ''}
                                styles={getSelectStyles(windowsSize)}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                                loadOptions={(inputValue: string) =>
                                    loadOptions(inputValue, category)
                                }
                                onChange={(newValue) => {
                                    setFilterValues({
                                        ...filterValues,
                                        [category]: newValue,
                                    });
                                    onFilterChange({
                                        ...filterValues,
                                        [category]: newValue,
                                    });
                                }}
                            />
                        </Popover.Panel>,
                        document.body,
                    )}
                </>
            )}
        </Popover>
    );
};

const getSelectStyles = (windowsSize: { width?: number; height?: number }) => ({
    control: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        borderRadius: 0,
        fontSize: '14px',
        minWidth: '198px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        backgroundColor: 'white',
        borderBottom: '1px solid #C6C6C6',
        width:
            windowsSize.width && windowsSize.width < 767
                ? windowsSize.width / 2 - 32
                : 'fit-content',
        border: windowsSize.width && windowsSize.width < 767 ? '1px solid #E0E0E0' : 'none',
    }),
    multiValue: (baseStyles: any) => ({
        ...baseStyles,
        maxWidth: '150px',
        zIndex: 9999,
        textTransform: 'uppercase',
    }),
    option: (baseStyles: any, state: OptionProps<any, true, GroupBase<any>>) => ({
        ...baseStyles,
        cursor: 'pointer',
        fontSize: '14px',
        backgroundColor: state.isSelected ? '#8D8D8D' : 'inherit',
        '&:hover': {
            backgroundColor: state.isSelected ? '#8D8D8D' : '#F4F4F4',
        },
        zIndex: 999,
        textTransform: 'uppercase',
    }),
    menuPortal: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        zIndex: 9999,
    }),
    menu: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        marginTop: -5,
        zIndex: 99999,
        borderRadius: 0,
        border: windowsSize.width && windowsSize.width < 767 ? '1px solid #E0E0E0' : 'none',
        borderTop: 'none',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    }),
});

export default FilterSelect;
