import { CircularProgress } from '@mui/material';
import React from 'react';

interface IProps {
    onClick?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    children?: React.ReactNode;
    fullWidth?: boolean;
}

const PrimaryButton: React.FC<IProps> = ({ onClick, disabled, isLoading, children, fullWidth }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={
                'py-4 px-7 md:px-14 text-sm text-white font-semibold bg-black hover:opacity-90 duration-100 flex items-center disabled:opacity-40 disabled:cursor-not-allowed ' +
                (fullWidth ? 'w-full' : '')
            }
        >
            <div className="gap-2 flex flex-row items-center justify-center w-full">
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {children}
            </div>
        </button>
    );
};

export default PrimaryButton;
