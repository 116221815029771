import React from 'react';

import { ReactComponent as FiltersIcon } from '../images/icons/filters.svg';

interface IProps {
    onClick: () => void;
}

const FiltersPanelButton: React.FC<IProps> = ({ onClick }) => {
    return (
        <button
            onClick={(event) => {
                event.stopPropagation();
                onClick();
            }}
            className="w-fit p-2 flex items-center border border-gray-04 rounded cursor-pointer duration-150 md:hidden"
        >
            <FiltersIcon className="w-[18px] h-full fill-gray-09" />
        </button>
    );
};

export default FiltersPanelButton;
