import React, { useEffect, useState } from 'react';

import ActionModal from '../../components/ActionModal';
import { editAlbumCover } from '../../services/editAlbumCover';
import { notifyAxiosError } from '../../utils';

interface IProps {
    isModalOpen: boolean;
    onDismiss: (success: boolean) => void;
    imageId: string;
    albumId: string;
}

export const SetImageAsAlbumCoverModal: React.FC<IProps> = ({
    onDismiss,
    isModalOpen,
    albumId,
    imageId,
}) => {
    const [isExecutingAction, setIsExecutingAction] = useState(false);
    const [success, setSucess] = useState(true);

    useEffect(() => {
        if (isModalOpen) {
            setSucess(false);
        }
    }, [isModalOpen]);

    const onPrimaryActionCallback = () => {
        setIsExecutingAction(true);
        editAlbumCover({ albumId: albumId, imageId: imageId })
            .then(() => setSucess(true))
            .catch((err) => notifyAxiosError(err))
            .finally(() => {
                setIsExecutingAction(false);
            });
    };

    return (
        <ActionModal
            isModalOpen={isModalOpen}
            onDismiss={() => onDismiss(success)}
            primaryActionTitle="Set Album cover"
            isExecutingAction={isExecutingAction}
            onPrimaryActionCallback={onPrimaryActionCallback}
            title="Set Album cover"
            showPrimaryButton={!success}
        >
            {!success ? (
                <div>Are you sure you want to set the selected image as album cover image?</div>
            ) : (
                <div>Image set as album cover successfully.</div>
            )}
        </ActionModal>
    );
};

export default SetImageAsAlbumCoverModal;
