import { mergeStyleSets, Modal } from '@fluentui/react';
import React from 'react';

import DefaultButton from './DefaultButton';
import PrimaryButton from './PrimaryButton';

interface IProps {
    isModalOpen: boolean;
    onDismiss: () => void;
    primaryActionTitle: string;
    isExecutingAction: boolean;
    onPrimaryActionCallback: () => void;
    children?: any;
    title: string;
    showPrimaryButton: boolean;
}
const contentStyles = mergeStyleSets({
    container: {
        position: 'relative',
        backGroundColor: 'rgba(0,0,0,0.48)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        overflow: 'auto',
        width: '90%',
        maxWidth: '825px',
        padding: '50px 40px',
    },
    paperFullWidth: {
        overflowY: 'visible',
    },
    dialogContentRoot: {
        overflowY: 'visible',
    },
});

export const ActionModal: React.FC<IProps> = ({
    onDismiss,
    isModalOpen,
    primaryActionTitle,
    isExecutingAction,
    onPrimaryActionCallback,
    children,
    title,
    showPrimaryButton,
}) => {
    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={() => onDismiss()}
            containerClassName={contentStyles.container}
            isBlocking={true}
        >
            <div className="space-y-8">
                <div className="text-2xl">{title}</div>
                <div>{children}</div>
                <div className="flex justify-end gap-x-4">
                    <DefaultButton disabled={isExecutingAction} onClick={() => onDismiss()}>
                        Close
                    </DefaultButton>
                    {showPrimaryButton && (
                        <PrimaryButton
                            onClick={onPrimaryActionCallback}
                            disabled={isExecutingAction}
                            isLoading={isExecutingAction}
                        >
                            {primaryActionTitle}
                        </PrimaryButton>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ActionModal;
