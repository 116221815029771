export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_AUTHORITY_ID}`,
        knownAuthorities: ['login.microsoftonline.com'],
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI || '',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};
export const loginRequest = {
    scopes: [`api://${process.env.REACT_APP_MSAL_CLIENT_ID}/Access`],
};
