import React, { useEffect, useState } from 'react';

import ActionModal from '../../components/ActionModal';
import { deleteAlbum } from '../../services/deleteAlbum';
import { notifyAxiosError } from '../../utils';

interface IProps {
    isModalOpen: boolean;
    onDismiss: (success: boolean) => void;
    albumId: string;
}

export const DeleteAlbumModal: React.FC<IProps> = ({ onDismiss, isModalOpen, albumId }) => {
    const [isExecutingAction, setIsExecutingAction] = useState(false);
    const [success, setSucess] = useState(true);

    useEffect(() => {
        if (isModalOpen) {
            setSucess(false);
        }
    }, [isModalOpen]);

    const onPrimaryActionCallback = () => {
        setIsExecutingAction(true);
        deleteAlbum({ albumId: albumId })
            .then(() => setSucess(true))
            .catch((err) => notifyAxiosError(err))
            .finally(() => {
                setIsExecutingAction(false);
            });
    };

    return (
        <ActionModal
            isModalOpen={isModalOpen}
            onDismiss={() => onDismiss(success)}
            primaryActionTitle="Delete album"
            isExecutingAction={isExecutingAction}
            onPrimaryActionCallback={onPrimaryActionCallback}
            title="Delete album"
            showPrimaryButton={!success}
        >
            {!success ? (
                <div>Are you sure you want to delete this album?</div>
            ) : (
                <div>Album deleted successfully.</div>
            )}
        </ActionModal>
    );
};

export default DeleteAlbumModal;
