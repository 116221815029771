import { useIsAuthenticated } from '@azure/msal-react';
import { Icon } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import FiltersPanelButton from '../../components/FiltersPanelButton';
import HideShowTags from '../../components/HideShowTags';
import IconButton from '../../components/IconButton';
import SmallBigViewSwitch from '../../components/SmallBigViewSwitch';
import SortButton from '../../components/SortButton';
import { SORT_OPTIONS } from '../../consts';
import { ReactComponent as DeleteIcon } from '../../images/icons/delete.svg';
import { ReactComponent as DividerIcon } from '../../images/icons/divider.svg';
import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';
import { ReactComponent as TypeImgIcon } from '../../images/icons/type-img.svg';
import { IAlbumsResponse, ISelectedSort, ITag } from '../../types';
import { formatNumber } from '../../utils';
import RemoveImageFromAlbumModal from './RemoveImageFromAlbumModal';
import SetImageAsAlbumCoverModal from './SetImageAsAlbumCoverModal';

interface IProps {
    albumId: string;
    hideTags: boolean;
    isBigImg: boolean;
    data: IAlbumsResponse;
    selectedAssets: string[];
    getSortedAssets: (sortOrder: string | null, sortField: string | null) => void;
    setBigImg: React.Dispatch<React.SetStateAction<boolean>>;
    setHideTags: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAssets: React.Dispatch<React.SetStateAction<string[]>>;
    setFilterModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDownloadAssetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMassiveDownloadAssetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Operations: React.FC<IProps> = ({
    albumId,
    data,
    isBigImg,
    hideTags,
    setBigImg,
    setHideTags,
    selectedAssets,
    getSortedAssets,
    setSelectedAssets,
    setFilterModalOpen,
    setIsDownloadAssetModalOpen,
    setIsMassiveDownloadAssetModalOpen,
}) => {
    const [searchParams] = useSearchParams();
    const [selectedSort, setSelectedSort] = useState<ISelectedSort | null>(null);

    const isAuth = useIsAuthenticated();

    const [allVisibileAssetsAreSelected, setAllVisibileAssetsAreSelected] = useState(false);

    const [isRemoveImageFromAlbumModalOpen, setIsRemoveImageFromAlbumModalOpen] = useState(false);
    const [isSetImageAsAlbumCoverModalOpen, setIsSetImageAsAlbumCoverModalOpen] = useState(false);

    useEffect(() => {
        if (selectedSort) getSortedAssets(selectedSort?.order ?? null, selectedSort?.field ?? null);
    }, [selectedSort]);

    useEffect(() => {
        setSelectedSort(null);
    }, [searchParams]);

    const onSelectedSortClear = useCallback(() => {
        setSelectedSort(null);
        getSortedAssets(null, null);
        close();
    }, [getSortedAssets]);

    return (
        <>
            <RemoveImageFromAlbumModal
                isModalOpen={isRemoveImageFromAlbumModalOpen}
                onDismiss={(success) => {
                    setIsRemoveImageFromAlbumModalOpen(false);
                    if (success) {
                        location.reload();
                    }
                }}
                assetsIds={selectedAssets}
                albumId={albumId}
            />

            {selectedAssets && selectedAssets.length === 1 && (
                <SetImageAsAlbumCoverModal
                    isModalOpen={isSetImageAsAlbumCoverModalOpen}
                    onDismiss={(success) => {
                        setIsSetImageAsAlbumCoverModalOpen(false);
                    }}
                    imageId={selectedAssets[0]}
                    albumId={albumId}
                />
            )}

            <div className="flex my-4">
                <div className="flex flex-col md:flex-row md:flex-wrap md:items-center text-gray-1">
                    <FiltersPanelButton
                        onClick={() => {
                            setFilterModalOpen(true);
                        }}
                    />
                    {data.elements && (
                        <>
                            <p className="text-gray-09 text-sm">
                                Total assets ({formatNumber(data.totalResults)})
                            </p>
                            {isAuth && (
                                <>
                                    <DividerIcon className="hidden md:block md:mx-5" />
                                    <p className=" text-gray-09 text-sm">
                                        Assets selected{' '}
                                        <span className="min-w-[25px] inline-block">
                                            {formatNumber(selectedAssets.length)}
                                        </span>
                                    </p>
                                    <DividerIcon className="hidden md:block md:mx-5" />
                                </>
                            )}

                            {isAuth && (
                                <div className="flex flex-wrap items-center">
                                    <CheckboxWithLabel
                                        value={allVisibileAssetsAreSelected}
                                        onToggle={() => {
                                            if (allVisibileAssetsAreSelected) {
                                                setSelectedAssets([]);
                                            } else {
                                                setSelectedAssets(
                                                    data.elements?.map((x) => x.id) ?? [],
                                                );
                                            }
                                            setAllVisibileAssetsAreSelected((prev) => !prev);
                                        }}
                                        label="Select all visible"
                                    />
                                    <DividerIcon className="mx-2 md:mx-6" />
                                    <IconButton
                                        title="Massive download"
                                        onClick={() => setIsMassiveDownloadAssetModalOpen(true)}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                    <DividerIcon className="mx-2 md:mx-6" />
                                    {selectedAssets.length > 0 &&
                                        data?.availableActions?.removeAlbumImage === true && (
                                            <>
                                                <IconButton
                                                    onClick={() =>
                                                        setIsRemoveImageFromAlbumModalOpen(true)
                                                    }
                                                    disabled={selectedAssets.length === 0}
                                                    title="Remove images from album"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <DividerIcon className="mx-2 md:mx-6" />
                                            </>
                                        )}
                                    {selectedAssets.length === 1 &&
                                        data?.availableActions?.setAlbumCoverImage === true &&
                                        !allVisibileAssetsAreSelected && (
                                            <>
                                                <IconButton
                                                    onClick={() =>
                                                        setIsSetImageAsAlbumCoverModalOpen(true)
                                                    }
                                                    disabled={selectedAssets.length !== 1}
                                                    title="Set image as album cover"
                                                >
                                                    <TypeImgIcon />
                                                </IconButton>
                                                <DividerIcon className="mx-2 md:mx-6" />
                                            </>
                                        )}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="ml-auto flex gap-x-[10px] items-center">
                    {data.elements && (
                        <SortButton
                            selectedSort={selectedSort}
                            setSelectedSort={(obj) => setSelectedSort(obj)}
                            onClear={onSelectedSortClear}
                            sortOptions={SORT_OPTIONS}
                        />
                    )}
                    {data.elements?.find((f) => f.tags?.find((t: ITag) => !t.hidden)) && (
                        <HideShowTags
                            hideTags={hideTags}
                            onClick={() => setHideTags((prev) => !prev)}
                        />
                    )}
                    {data.elements && (
                        <SmallBigViewSwitch
                            isBigImg={isBigImg}
                            setBigImg={(newValue) => setBigImg(newValue)}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Operations;
