declare global {
    interface Window {
        gtag: any;
    }
}

export const sendGoogleAnalyticsEvent = (eventName: string, additionalParam?: any) => {
    const params = {
        page_location: window.location.href,
        page_path: location.pathname,
        send_to: 'G-6JMTDB5FTN',
    };
    const requestParams = { ...params, additionalParam };

    window.gtag('event', eventName, requestParams);
};
