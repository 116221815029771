import React from 'react';

interface IProps {
    onClick: () => void;
    disabled?: boolean;
    title?: string;
    children?: any;
}

const IconButton: React.FC<IProps> = ({ onClick, disabled, title, children }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            title={title}
            className="outline-none disabled:hover:bg-transparent disabled:cursor-not-allowed hover:scale-110 disabled:hover:scale-100 rounded-full duration-100"
        >
            {children}
        </button>
    );
};

export default IconButton;
