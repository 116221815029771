import React from 'react';

interface IProps {
    hideTags: boolean;
    onClick: () => void;
}

const HideShowTags: React.FC<IProps> = ({ hideTags, onClick }) => {
    return (
        <div
            className={` h-9 flex items-center justify-center rounded cursor-pointer duration-100 ${
                !hideTags ? 'bg-black' : 'bg-gray-02'
            }`}
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className={`w-8 ${!hideTags ? 'fill-white' : 'fill-gray-05'}`}
            >
                <path d="M11 9H13V7H11V9M14 17V15H13V11H10V13H11V15H10V17H14" />
            </svg>
        </div>
    );
};

export default HideShowTags;
