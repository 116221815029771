import React from 'react';

interface IProps {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    type: string;
}

const AssetTypeIcon: React.FC<IProps> = ({ Icon, type }) => {
    return (
        <div className="flex items-center gap-x-2 px-3 py-[3.5px] border-[0.5px] border-gray-05 bg-white text-[12px] rounded-[50px] ml-auto group-hover:opacity-100 opacity-0 duration-100">
            <Icon className="fill-gray-06 w-[18px] h-[15px] " />
            <p className="capitalize text-gray-05">{type}</p>
        </div>
    );
};

export default AssetTypeIcon;
