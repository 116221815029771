import { allApis as allGeneratedApi } from './allApis';

export const disclaimerTag = 'disclaimer';
export const allApis = allGeneratedApi.enhanceEndpoints({
    addTagTypes: [disclaimerTag],
    endpoints: {
        getDisclaimerGetDisclaimers: {
            providesTags: [disclaimerTag],
        },
        postDisclaimerInsertDisclaimerLog: {
            invalidatesTags: [disclaimerTag],
        },
    },
});
