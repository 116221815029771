import React, { useMemo } from 'react';

import { ReactComponent as DownloadAssetIcon } from '../../images/icons/download-asset.svg';
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg';
import { ReactComponent as TypeDocumentIcon } from '../../images/icons/type-document.svg';
import { ReactComponent as TypeExcellIcon } from '../../images/icons/type-excell.svg';
import { ReactComponent as TypeImgIcon } from '../../images/icons/type-img.svg';
import { ReactComponent as TypePDFIcon } from '../../images/icons/type-pdf.svg';
import { ReactComponent as TypePowerPointIcon } from '../../images/icons/type-powerpoint.svg';
import { ReactComponent as TypeVideoIcon } from '../../images/icons/type-video.svg';
import { ReactComponent as TypeZipIcon } from '../../images/icons/type-zip.svg';
import NotFoundImg from '../../images/image-not-found.png';
import { allApis } from '../../store/rtk/';
import { ITag } from '../../types';
import { downlaodByUrl, getAssetDownloadUrl, getRawDownloadUrl } from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../utils/extendedGlobal';
import CategorizedTag from '../CategorizedTag';
import Checkbox from '../Checkbox';
import AssetVideo from '../video';
import AssetTypeIcon from './icon';

interface IProps {
    element: any;
    isBigImg?: boolean;
    hideTags?: boolean;
    selectedAssets?: string[];
    toggleSelected?: (id: string, isAdd: boolean) => void;
    setPreviewedAsset: React.Dispatch<React.SetStateAction<number>>;
    allAssetsAreSelected?: boolean;
    setIsDownloadAssetModalOpen: (open: boolean) => void;
}

const Asset: React.FC<IProps> = ({
    element,
    hideTags,
    isBigImg,
    toggleSelected,
    selectedAssets,
    setPreviewedAsset,
    allAssetsAreSelected,
    setIsDownloadAssetModalOpen,
}) => {
    const { Icon, type } = useMemo(() => {
        let Icon = TypeDocumentIcon;
        let type = element.resourceType;

        switch (element.file.extension?.toLowerCase()) {
            case 'xlsx':
                Icon = TypeExcellIcon;
                type = 'Excel';
                break;
            case 'xls':
                Icon = TypeExcellIcon;
                type = 'Excel';
                break;
            case 'ppt':
                Icon = TypePowerPointIcon;
                type = 'PowerPoint';
                break;
            case 'pptx':
                Icon = TypePowerPointIcon;
                type = 'PowerPoint';
                break;
            case 'pdf':
                Icon = TypePDFIcon;
                type = 'PDF';
                break;
            case 'zip':
                Icon = TypeZipIcon;
                type = 'ZIP';
                break;
            case 'jpeg':
                Icon = TypeImgIcon;
                type = 'Image';
                break;
            case 'jpg':
                Icon = TypeImgIcon;
                type = 'Image';
                break;
            case 'mp4':
                Icon = TypeVideoIcon;
                type = 'Video';
                break;
            case 'html':
                type = 'HTML';
                break;
            case 'txt':
                type = 'Text';
                break;
        }
        return { Icon, type };
    }, [element]);

    const isAssetSelected = selectedAssets?.includes(element.id) || allAssetsAreSelected;
    const selectDisabled = allAssetsAreSelected;

    const [getAzureUrlApiTrigger] = allApis.useLazyPostSmartBrowsingGetAzureUrlQuery();

    const downloadClick = async () => {
        if (
            element.resourceType == 'image' ||
            (element.resourceType == 'video' && element.storageTypeId != 'AZ')
        ) {
            downlaodByUrl(element.file.fullName, getAssetDownloadUrl(element) ?? '');
        } else if (element.resourceType == 'raw' || element.storageTypeId == 'AZ') {
            try {
                const azureUrl = await getAzureUrlApiTrigger({
                    assetId: element.id,
                    storageAccountContainerName: element.azureStorage.containerName,
                }).unwrap();
                getRawDownloadUrl(element.file.fullName, azureUrl.url ?? '');
            } catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <div
            className={`relative w-full group bg-white mx-auto border rounded text-gray-1 hover:border-gray-03 duration-150 ${
                isBigImg && 'col-span-2'
            }`}
            style={{
                boxShadow: isAssetSelected ? '0px 4px 34px rgba(0, 0, 0, 0.15)' : 'none',
                borderColor: isAssetSelected ? '#C6C6C6' : '#E0E0E0',
            }}
        >
            <div className="w-full duration-75 flex items-center justify-between absolute top-3 left-0 px-2 z-30 ">
                <div className="flex items-center gap-x-2">
                    {selectedAssets && toggleSelected && (
                        <div className="ml-2">
                            <Checkbox
                                value={isAssetSelected ?? false}
                                onToggle={() => {
                                    toggleSelected(
                                        element.id,
                                        !selectedAssets.includes(element.id),
                                    );
                                }}
                                disabled={selectDisabled ?? false}
                                isOpacityZero={!isAssetSelected}
                            />
                        </div>
                    )}

                    <DownloadAssetIcon
                        onClick={() => downloadClick()}
                        className="group-hover:opacity-100 opacity-0 cursor-pointer hover:scale-105 duration-100"
                    />
                </div>
                <AssetTypeIcon Icon={Icon} type={type} />
            </div>
            <div
                className={`relative w-full overflow-hidden duration-150 group flex justify-center items-center ${
                    isBigImg ? 'h-[420px]' : 'h-[210px]'
                }`}
            >
                {element.resourceType === 'image' && (
                    <>
                        <img
                            src={`https:${element.cloudinary?.urlPrefix}${
                                element.file?.extension === 'pdf' ? 'f_auto' : 'c_thumb'
                            },q_auto,h_420/v${element.cloudinary?.version}/${element.cloudinary
                                ?.publicId}`}
                            loading="lazy"
                            alt="Asset photo"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = NotFoundImg;
                            }}
                            className="w-full h-full object-contain "
                        />
                        <div
                            onClick={() => {
                                if (element.file?.extension === 'pdf') {
                                    sendGoogleAnalyticsEvent(
                                        'asset_opened_' + element.file.fullName,
                                        'document',
                                    );
                                    window.open(
                                        `https:${element.cloudinary?.urlPrefix}c_thumb,q_auto,h_210/v${element.cloudinary?.version}/${element.cloudinary?.publicId}`,
                                    );
                                } else {
                                    sendGoogleAnalyticsEvent(
                                        'asset_opened_' + element.file.fullName,
                                        'image',
                                    );
                                    setPreviewedAsset(element);
                                }
                            }}
                            className={`w-full opacity-0 h-full group-hover:opacity-100 absolute bottom-0 left-0 flex items-center justify-center text-white text-sm font-bold duration-500 cursor-pointer`}
                            style={{
                                background:
                                    'linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.8) 100%)',
                            }}
                        >
                            <span
                                className={`mt-auto text-center ${
                                    isBigImg ? 'text-[13px] mb-10' : 'text-[11px] mb-5 '
                                }`}
                            >
                                {element.file?.fullName}
                            </span>
                            <button
                                className={`absolute flex items-center outline-none border-none font-bold -translate-x-1 ${
                                    isBigImg ? 'bottom-24' : 'bottom-14 text-sm'
                                }`}
                            >
                                <SearchIcon fill="white" className="w-7" /> Full view
                            </button>
                        </div>
                    </>
                )}
                {element.resourceType === 'video' && (
                    <>
                        <AssetVideo
                            height={isBigImg ? 420 : 210}
                            storageTypeId={element.storageTypeId}
                            extension={element.file.extension}
                            cloudinary={element.cloudinary}
                        />
                        <div
                            onClick={() => {
                                sendGoogleAnalyticsEvent(
                                    'asset_opened_' + element.file.fullName,
                                    'video',
                                );
                                setPreviewedAsset(element);
                            }}
                            style={{
                                background:
                                    'linear-gradient(360deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0.8) 100%)',
                            }}
                            className={`w-full pt-3 absolute top-0 left-0 flex flex-col  group-hover:translate-y-0  items-center text-white font-bold  duration-200 cursor-pointer ${
                                isBigImg
                                    ? 'text-[13px]  h-[222px] -translate-y-[224px]'
                                    : 'text-[11px] h-[111px] -translate-y-[112px]'
                            }`}
                        >
                            <p className={`text-center ${isBigImg ? 'mt-12' : 'mt-7'}`}>
                                {element.file?.fullName}
                            </p>
                            <button
                                className={`flex items-center outline-none border-none font-bold -translate-x-1 ${
                                    isBigImg ? 'mt-7' : 'text-sm mt-2 '
                                }`}
                            >
                                <SearchIcon fill="white" className="w-5 h-3" /> Full view
                            </button>
                        </div>
                    </>
                )}
                {element.resourceType == 'raw' && (
                    <>
                        <div>
                            <Icon className="w-12" />
                            <p className="text-center text-gray-06 font-semibold text-sm mt-2">
                                {type}
                            </p>
                        </div>
                        <div
                            className="w-full h-full opacity-0  group-hover:opacity-100 absolute bottom-0 left-0 flex items-center justify-center text-white text-sm font-bold duration-500 cursor-pointer"
                            style={{
                                background:
                                    'linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.8) 100%)',
                            }}
                        >
                            <span
                                className={`mt-auto text-center ${
                                    isBigImg ? 'text-[13px] mb-10' : 'text-[11px] mb-5 '
                                } `}
                            >
                                {element.file?.fullName}
                            </span>
                        </div>
                    </>
                )}
                {!element.resourceType && <div className="text-sm">Resource type is not set</div>}
            </div>
            {!hideTags && (
                <div className="space-y-2 p-4">
                    {element?.tags
                        ?.filter((tag: ITag) => !tag.hidden)
                        .map((tag: ITag, idx: number) => <CategorizedTag tag={tag} key={idx} />)}
                </div>
            )}
        </div>
    );
};

export default Asset;
