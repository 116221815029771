import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ClearFilterButton from '../../components/ClearFilterButton';
import FilterContainer from '../../components/FilterContainer';
import FilterSelect from '../../components/filterSelect';
import SearchBox from '../../components/SearchBox';
import { useDebounce } from '../../hooks/useDebouce';
import { ReactComponent as ClearIcon } from '../../images/icons/clear.svg';
import { getAlbumFilters } from '../../services/getAlbumFilters';
import { getAlbumFilterValues } from '../../services/getAlbumFilterValues';
import { ITag } from '../../types';
import { addTagsToParamObj, notifyAxiosError } from '../../utils';

interface IProps {
    isFilterModalOpen: boolean;
    setFilterModalOpen: (value: boolean) => void;
    albumId: string;
}

const Filter: React.FC<IProps> = ({ isFilterModalOpen, setFilterModalOpen, albumId }) => {
    const [freeText, setFreeText] = useState<string>();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
    const [searchParams] = useSearchParams();
    const [filterData, setFilterData] = useState<string[]>([]);
    const navigate = useNavigate();
    const params = useMemo(() => {
        const param = searchParams.get('req');
        setFreeText(undefined);
        if (param) {
            try {
                const parsedParam = JSON.parse(param);
                if (parsedParam.freeText) setFreeText(parsedParam.freeText);
                return parsedParam;
            } catch (err) {
                console.error(err);
            }
        } else return null;
    }, [searchParams]);

    useEffect(() => {
        getAlbumFilters({ albumId: albumId })
            .then((res) => setFilterData(res.data))
            .catch((err) => notifyAxiosError(err));
    }, [albumId]);

    useEffect(() => {
        if (!params?.tags) setFilterValues({});
        else {
            const newFilters: any = {};
            params.tags.forEach((tag: ITag) => {
                if (!filterData.includes(tag.category) || !tag.value || tag.value[0] === null)
                    return;
                newFilters[tag.category] = tag.value.map((val) => ({
                    value: val,
                    label: val,
                }));
            });
            setFilterValues(newFilters);
        }
    }, [searchParams, filterData]);

    const onFreeTextChange = useDebounce(
        useCallback(
            (text: string) => {
                try {
                    let tempParams = params;
                    if (!tempParams) {
                        tempParams = {
                            freeText: text,
                            aggregationId: '0',
                        };
                    } else {
                        tempParams.freeText = text;
                    }
                    navigate(`?req=${encodeURIComponent(JSON.stringify(tempParams))}`);
                } catch (err) {
                    console.error(err);
                }
            },
            [params],
        ),
        500,
    );

    const onFilterChange = useCallback(
        (values: { [key: string]: any }) => {
            try {
                const newParams = addTagsToParamObj(params ?? {}, values);
                navigate(`?req=${encodeURIComponent(JSON.stringify(newParams))}`);
            } catch (err) {
                console.error(err);
            }
        },
        [params],
    );

    const loadOptions = useCallback(
        (inputValue: string, category: string): any => {
            return getAlbumFilterValues({
                tags: params?.tags?.filter((tag: ITag) => tag.hidden) ?? [
                    {
                        category: 'Folder',
                        value: ['/'],
                    },
                ],
                field: category,
                filterFreeText: inputValue,
                freeText: freeText,
                albumId: albumId,
            })
                .then((res) =>
                    res.status === 200
                        ? res.data.map((item: { id: string; value: string }) => ({
                              value: item.id,
                              label: item.value,
                          }))
                        : [],
                )
                .catch((err) => {
                    notifyAxiosError(err);
                });
        },
        [params, albumId, freeText],
    );

    const onFilterReset = useCallback(() => {
        const newParams: any = {};
        if (params) {
            if (params.tags)
                newParams.tags = params.tags.filter((tag: ITag) => !filterValues[tag.category]);
            newParams.aggregationId = params.aggregationId;
        }
        navigate(`?req=${encodeURIComponent(JSON.stringify(newParams))}`);
    }, [filterValues, params]);

    const clearFilterDisabled =
        (Object.keys(filterValues).length === 0 ||
            Object.keys(filterValues).every((key) => filterValues[key].length === 0)) &&
        !!!freeText;

    return (
        <FilterContainer isFilterModalOpen={isFilterModalOpen}>
            <div className="flex justify-between items-center mb-8 md:hidden">
                <h3 className="uppercase font-bold">Filters</h3>
                <ClearIcon className="w-4 h-4" onClick={() => setFilterModalOpen(false)} />
            </div>
            <div className="flex flex-wrap gap-[10px] items-center justify-center md:justify-start">
                <div className={`w-full group md:w-[calc(100%-50px)]`}>
                    <SearchBox
                        value={freeText}
                        onChange={(newValue) => {
                            setFreeText(newValue);
                            onFreeTextChange(newValue);
                        }}
                    />
                </div>

                <ClearFilterButton disabled={clearFilterDisabled} onClick={onFilterReset} />

                {filterData &&
                    filterData.map((category, idx) => (
                        <FilterSelect
                            params={params}
                            category={category}
                            loadOptions={loadOptions}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            onFilterChange={onFilterChange}
                            key={`${JSON.stringify(params)}${idx}`}
                        />
                    ))}
            </div>
        </FilterContainer>
    );
};

export default Filter;
