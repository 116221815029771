import React from 'react';

import { ReactComponent as CheckIcon } from '../images/icons/check.svg';

interface IProps {
    value: boolean;
    onToggle: () => void;
    label: string;
    checkIconFillColor?: string;
    textStyle?: React.CSSProperties;
}

const CheckboxWithLabel: React.FC<IProps> = ({
    value,
    onToggle,
    label,
    checkIconFillColor,
    textStyle,
}) => {
    return (
        <div
            className="flex items-center cursor-pointer gap-x-3 hover:opacity-[0.85]"
            onClick={() => {
                onToggle();
            }}
        >
            <div
                className={`w-[15px] h-[15px] border-[1.5px] border-gray-06 rounded-[2px] flex items-center ${
                    value && 'bg-gray-06'
                } cursor-pointer`}
            >
                <CheckIcon fill={checkIconFillColor ?? '#F7F7F7'} />
            </div>
            <p
                style={
                    textStyle ?? {
                        userSelect: 'none',
                        fontSize: '14px',
                        color: '#474747',
                    }
                }
            >
                {label}
            </p>
        </div>
    );
};

export default CheckboxWithLabel;
