import { Popover, Transition } from '@headlessui/react';
import React from 'react';

import { ITag } from '../types';

interface IProps {
    tag: ITag;
}

const CategorizedTag: React.FC<IProps> = ({ tag }) => {
    if (tag.value.length === 0) return null;
    return (
        <div className="grid grid-cols-2">
            <div className="text-gray-06 capitalize whitespace-nowrap leading-[18px] truncate">
                {tag.category}
            </div>

            <div>
                <Popover className="relative text-sm font-normal leading-[18px] min-w-[100px]">
                    {() => (
                        <>
                            <Popover.Button className="w-full outline-none grid ">
                                <p className="truncate text-right capitalize hover:opacity-80 cursor-pointer">
                                    {tag.value.join(', ').toLocaleLowerCase()}
                                </p>
                            </Popover.Button>
                            <Transition
                                as={React.Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute right-0 w-full opacity-100 z-50">
                                    <div
                                        className="relative bg-white opacity-100 w-full break-words p-2 capitalize rounded"
                                        style={{
                                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                        }}
                                    >
                                        <p className="text-base text-gray-05">{tag.category}:</p>
                                        {tag.value.join(', ').toLocaleLowerCase()}
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </div>
        </div>
    );
};

export default CategorizedTag;
