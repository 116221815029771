import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { ISmartBrowsingResponse, ITag } from '../types';
import { sendGoogleAnalyticsEvent } from './extendedGlobal';

export const encodeURLParam = (param: any) => {
    return encodeURIComponent(JSON.stringify(param));
};

export const getFilterCategories = (data: ISmartBrowsingResponse) => {
    const filters: Set<string> = new Set();
    if (data && (data.smartFolders || data.elements)) {
        const items = data.smartFolders || data.elements;
        items?.map(
            (folder) =>
                folder.tags?.forEach((tag: ITag) => !tag.hidden && filters.add(tag.category)),
        );
    }
    return Array.from(filters);
};

export function addTagsToParamObj(params: any, filterValues: { [key: string]: any }) {
    if (!params.tags) params.tags = [];
    if (Object.keys(filterValues).length === 0) {
        params.tags = [params.tags.find((tag: ITag) => tag.category === 'Folder')];
    }
    Object.keys(filterValues).forEach((key) => {
        const tagIdx = params.tags.findIndex((tag: ITag) => tag.category === key);
        if (tagIdx !== -1) {
            params.tags[tagIdx].value = filterValues[key].map((item: any) => item.value);
            params.tags[tagIdx].hidden = false;
        } else {
            params.tags.push({
                category: key,
                value: filterValues[key].map((item: any) => item.value),
                hidden: false,
            });
        }
    });
    return params;
}

export function signOutClickHandler(instance: any, homeAccountId: string) {
    const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: '/',
    };
    instance.logoutRedirect(logoutRequest);
}

export function isEmptyObject(obj: object) {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
}

export function notifyError(message: string) {
    toast.error(truncate(message, 200), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    });
}

export function notifyAxiosError(error: any) {
    if (error.response) {
        console.error(error.response.status);
        console.error(error.response.headers);
        notifyError(error.response.data?.title || error.response.data);
    } else if (error.request && typeof error.request === 'string') {
        notifyError(error.request);
    } else {
        notifyError(error.message);
    }
}

export function truncate(str: string, n: number) {
    return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

export const getAssetDownloadUrl = (asset: any): string | undefined => {
    if (asset.resourceType === 'image' && asset.cloudinary) {
        if (asset.cloudinary?.format !== 'pdf')
            return encodeURI(
                `https:${asset.cloudinary?.urlPrefix}fl_attachment/v${asset.cloudinary?.version}/${asset.cloudinary?.publicId}`,
            );
        else
            return encodeURI(
                `https:${asset.cloudinary?.urlPrefix}fl_attachment/v${asset.cloudinary?.version}/${asset.cloudinary?.publicId}.${asset.cloudinary.format}`,
            );
    } else if (asset.resourceType === 'video' && asset.cloudinary) {
        return encodeURI(
            `https:${asset.cloudinary?.urlPrefix}fl_attachment/v1/${asset.cloudinary?.publicId}.${asset.cloudinary.format}`,
        );
    } else if (asset.resourceType === 'raw') {
        return undefined;
    }

    return undefined;
};

export function formatNumber(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function formatDate(x: any) {
    return dayjs(x).format('DD/MM/YYYY, HH:mm');
}

export const getRawDownloadUrl = async (assetName: string, azureUrl: string) => {
    const newUrl = azureUrl;
    const temporaryDownloadLink = document.createElement('a');
    temporaryDownloadLink.setAttribute('href', newUrl);
    temporaryDownloadLink.setAttribute('download', assetName);
    temporaryDownloadLink.click();
    sendGoogleAnalyticsEvent('download_asset_' + assetName);
};

export const downlaodByUrl = async (assetName: string, assetUrl: string) => {
    const temporaryDownloadLink = document.createElement('a');
    temporaryDownloadLink.setAttribute('href', assetUrl);
    temporaryDownloadLink.setAttribute('download', assetName);
    temporaryDownloadLink.click();
    sendGoogleAnalyticsEvent('download_asset_' + assetName);
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
