import { useEffect, useState } from 'react';

export const DATA_TYPE_SMARTBROWSINGHOMEPAGE = 'SmartBrowsingHomePage';
export const DATA_TYPE_SMARTBROWSING = 'SmartBrowsing';
export const DATA_TYPE_FREEBROWSING = 'FreeBrowsing';

const useDataType = (data: any) => {
    const [dataType, setDataType] = useState<string>();

    useEffect(() => {
        const resultType = data.resultType;

        switch (resultType) {
            case 'RootSmartFolder':
                setDataType(DATA_TYPE_SMARTBROWSINGHOMEPAGE);
                break;
            case 'SmartFolder':
                setDataType(DATA_TYPE_SMARTBROWSING);
                break;
            case 'Assets':
                setDataType(DATA_TYPE_FREEBROWSING);
                break;
        }
    }, [data]);

    return dataType;
};

export default useDataType;
