import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Tabs from '../../common/tabs';
import ClearFilterButton from '../../components/ClearFilterButton';
import SearchBox from '../../components/SearchBox';
import { HOME_TABS, ROUTES } from '../../consts';
import { useDebounce } from '../../hooks/useDebouce';
import { LoaderContext } from '../../routes';
import { getAlbums } from '../../services/getAlbums';
import { notifyAxiosError } from '../../utils';
import Album from './album';

const Albums: React.FC = () => {
    const [selectedTab, setselectedTab] = useState<string>(HOME_TABS[2].value);
    const [searchParams] = useSearchParams();
    const [freeText, setFreeText] = useState('');
    const [data, setData] = useState<any>();
    const ctxt = useContext(LoaderContext);
    const navigate = useNavigate();

    useEffect(() => {
        setFreeText('');
        const params = searchParams.get('req');
        if (!params) {
            getData({ pageSize: 50 });
        } else {
            try {
                const parsedParams = JSON.parse(params);
                if (parsedParams && parsedParams.freeText) setFreeText(parsedParams.freeText);
                getData({ ...parsedParams, pageSize: 50 });
            } catch (err) {
                console.error(err);
            }
        }
    }, [searchParams]);

    const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
        setselectedTab(newValue);
        if (newValue === HOME_TABS[1].value) {
            navigate(
                `/?req=${encodeURIComponent(
                    JSON.stringify({
                        pageSize: 50,
                    }),
                )}`,
            );
        } else if (newValue === HOME_TABS[0].value) navigate('/');
    }, []);

    const getData = useCallback(
        (reqData: any, loadMore = false) => {
            ctxt?.setLoaderIsActive(true);
            getAlbums(reqData)
                .then((res) => {
                    if (res.status === 200) {
                        if (loadMore) res.data.elements = [...data.elements, ...res.data.elements];
                        setData(res.data);
                    }
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => ctxt?.setLoaderIsActive(false));
        },
        [data],
    );

    const onSearch = useDebounce(
        useCallback((newValue: string) => {
            const freeText = newValue;
            navigate(
                `?req=${encodeURIComponent(
                    JSON.stringify({
                        freeText,
                    }),
                )}`,
            );
        }, []),
        500,
    );

    const onSearchReset = useCallback(() => {
        navigate(ROUTES.ALBUMS);
    }, []);

    return (
        <>
            <Tabs selectedTab={selectedTab} handleChange={handleTabChange} tabs={HOME_TABS} />
            {data && (
                <div className="px-[16px] md:px-[32px] py-[7px] md:py-[14px]">
                    <h1 className="font-bold text-[28px] leading-[32.2px] text-gray-10 mb-[10px]">
                        All albums
                    </h1>
                    <div
                        onClick={(event) => event.stopPropagation()}
                        className="bg-white rounded p-[10px] duration-200"
                    >
                        <div className="flex gap-x-[10px] gap-y-[10px] items-center">
                            <div className="relative w-full group">
                                <SearchBox
                                    value={freeText}
                                    onChange={(newValue) => {
                                        setFreeText(newValue);
                                        onSearch(newValue);
                                    }}
                                />
                            </div>

                            <ClearFilterButton
                                disabled={!!!freeText}
                                onClick={onSearchReset}
                                notUserFullClearFIlter={true}
                            />
                        </div>
                    </div>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 4xl:grid-cols-6 gap-6">
                        {data.elements &&
                            data.elements.map((element: any) => (
                                <Album key={element.id} album={element} />
                            ))}
                    </div>
                    {data.hasMore && data?.elements && (
                        <div className="flex justify-center my-6">
                            {data?.elements.length === 0 ? 'No Result Found' : 'No More Data '}{' '}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Albums;
