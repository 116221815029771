import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';

interface IData {
    name?: string;
    existingAlbumId?: string;
    assetIds: string[];
    searchQuery?: any;
    disclaimerAccepted?: boolean;
}

export const createAlbum = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post(`${BaseUrl}Album/CreateAlbum`, data, auth);
};
