import './styles/index.css';
import './styles/custom.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { createTheme, ThemeProvider } from '@fluentui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { CommandDataProvider } from './context/CommandDataContext';
import { msalConfig } from './helpers/auth/config';
import { store } from './store/store';

initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);

const myTheme = createTheme({
    palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <MsalProvider instance={msalInstance}>
        <BrowserRouter basename="/">
            <ThemeProvider theme={myTheme}>
                <Provider store={store}>
                    <CommandDataProvider>
                        <App />
                    </CommandDataProvider>
                </Provider>
            </ThemeProvider>
        </BrowserRouter>
    </MsalProvider>,
);
