import { CircularProgress } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useSearchParams } from 'react-router-dom';

import HomeTabs from '../../common/tabs';
import Asset from '../../components/asset';
import AssetPreview from '../../components/asset/preview';
import DownloadAssetsModal from '../../components/downloadModal';
import { MassiveDownloadModal } from '../../components/MassiveDownloadModal';
import { HOME_TABS, PAGE_SIZE, ROUTES } from '../../consts';
import { CommandDataContext } from '../../context/CommandDataContext';
import { LoaderContext } from '../../routes';
import { getSmartBrowsingAssets } from '../../services/getAssets';
import { getSmartBrowsing } from '../../services/getSmartBrowsing';
import { ISmartBrowsingResponse } from '../../types';
import { notifyAxiosError } from '../../utils';
import BreadCrumb from './breadcrumb';
import Filters from './filters';
import Folder from './folder';
import ShareModal from './shareModal';

let previousSmartBrowsingTabParams: string;
let previousFreeBrowsingTabParams: string;

export const Home: React.FC = () => {
    const [isDownloadAssetModalOpen, setIsDownloadAssetModalOpen] = useState(false);
    const [isMassiveDownloadAssetModalOpen, setIsMassiveDownloadModalOpen] = useState(false);
    const [currentItems, setCurrentItems] = useState<'FOLDER' | 'ASSET'>('FOLDER');
    const [getMoreDataLoading, setGetMoreDataLoading] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    const [isShareModalOpen, setShareModalState] = useState(false);
    const [previewedAsset, setPreviewedAsset] = useState<any>();
    const [data, setData] = useState<ISmartBrowsingResponse>();
    const [hideTags, setHideTags] = useState(false);
    const [isBigImg, setBigImg] = useState(false);
    const [searchParams] = useSearchParams();
    const [sortParams, setSortParams] = useState<{
        sortOrder: string | null;
        sortField: string | null;
    }>();
    const [sortParamsSmartBrowsing, setSortParamsSmartBrowsing] = useState<{
        sortOrder: string | null;
        sortField: string | null;
    }>();
    const [includeNotSet, setIncludeNotSet] = useState(true);

    const ctxt = useContext(LoaderContext);
    const navigate = useNavigate();

    const { selectedTab, setSelectedTab } = useContext(CommandDataContext);

    const { selectAllAssets } = useContext(CommandDataContext);

    useEffect(() => {
        setPreviewedAsset(null);
    }, [selectedTab]);

    useEffect(() => {
        setSelectedAssets((prev) => (prev.length > 0 ? [] : prev));
        const params = searchParams.get('req');

        if (!params) {
            setSelectedTab?.(HOME_TABS[0].value);
            getFolders({
                aggregationId: '0',
            });
            setCurrentItems('FOLDER');
        } else {
            try {
                const parsedParams = JSON.parse(params);
                if (parsedParams.aggregationId) {
                    getFolders({ ...parsedParams, includeNotSet: includeNotSet });
                    setCurrentItems('FOLDER');
                    window.scrollTo(0, 0);
                } else {
                    const isLastPageOfSmartBrowsing = !!parsedParams?.tags?.find(
                        (t: any) => t.category === 'Folder',
                    );

                    if (isLastPageOfSmartBrowsing) {
                        setSelectedTab?.(HOME_TABS[0].value);
                    } else {
                        setSelectedTab?.(HOME_TABS[1].value);
                    }

                    getAssets({
                        tags: parsedParams.tags,
                        pageSize: PAGE_SIZE,
                        freeText: parsedParams.freeText,
                        sortField: 'file.fullName.keyword',
                        sortOrder: 'ASC',
                    });
                    setCurrentItems('ASSET');
                    window.scrollTo(0, 0);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        setHideTags(currentItems !== 'FOLDER');
    }, [currentItems]);

    const handleTabChange = useCallback(
        (_: React.SyntheticEvent, newValue: string) => {
            setSelectedTab?.(newValue);
            const params = searchParams.get('req');
            if (newValue === HOME_TABS[1].value) {
                previousSmartBrowsingTabParams = params || '';
                if (previousFreeBrowsingTabParams) {
                    navigate(`?req=${encodeURIComponent(previousFreeBrowsingTabParams)}`);
                } else
                    navigate(
                        `?req=${encodeURIComponent(
                            JSON.stringify({
                                pageSize: PAGE_SIZE,
                            }),
                        )}`,
                    );
            } else if (newValue === HOME_TABS[0].value) {
                previousFreeBrowsingTabParams = params || '';
                if (previousSmartBrowsingTabParams) {
                    navigate(
                        `${ROUTES.HOME}?req=${encodeURIComponent(previousSmartBrowsingTabParams)}`,
                    );
                } else navigate(ROUTES.HOME);
            } else if (newValue === HOME_TABS[2].value) navigate(ROUTES.ALBUMS);
        },
        [searchParams],
    );

    const getFolders = useCallback(
        (data: any) => {
            ctxt?.setLoaderIsActive(true);
            getSmartBrowsing({ ...data, pageSize: PAGE_SIZE })
                .then((res) => {
                    if (res.status === 200) {
                        setData(res.data);
                    }
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => ctxt?.setLoaderIsActive(false));
        },
        [ctxt],
    );

    const getAssets = useCallback(
        (data: any) => {
            ctxt?.setLoaderIsActive(true);
            getSmartBrowsingAssets(data)
                .then((res) => {
                    if (res.status === 200) {
                        setData(res.data);
                    }
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => ctxt?.setLoaderIsActive(false));
        },
        [ctxt],
    );

    const getMoreFolders = useCallback(() => {
        if (!data) return;
        let parsedParams;
        try {
            const params = searchParams.get('req');
            if (params) parsedParams = JSON.parse(params);
            setGetMoreDataLoading(true);
            getSmartBrowsing({
                aggregationId: parsedParams.aggregationId || '0',
                tags: parsedParams.tags || [],
                pageSize: PAGE_SIZE,
                nextPaginationInfo: data.nextPaginationInfo,
                sortingTags: sortParamsSmartBrowsing?.sortField
                    ? [
                          {
                              field: sortParamsSmartBrowsing?.sortField,
                              order: sortParamsSmartBrowsing?.sortOrder || 'DESC',
                          },
                      ]
                    : undefined,
                includeNotSet: includeNotSet,
            })
                .then((res) => {
                    if (data.smartFolders)
                        res.data.smartFolders = [
                            ...(data.smartFolders ?? []),
                            ...(res.data.smartFolders ?? []),
                        ];
                    setData(res.data);
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => setGetMoreDataLoading(false));
        } catch (err) {
            console.error(err);
        }
    }, [data, searchParams, includeNotSet]);

    const getMoreAssets = useCallback(() => {
        const params = searchParams.get('req');
        if (!params || !data) return;
        try {
            const parsedParams = JSON.parse(params);
            setGetMoreDataLoading(true);
            getSmartBrowsingAssets({
                tags: parsedParams.tags,
                pageSize: PAGE_SIZE,
                nextPaginationInfo: data.nextPaginationInfo,
                freeText: parsedParams.freeText,
                sortOrder: sortParams?.sortOrder || 'ASC',
                sortField: sortParams?.sortField || 'file.fullName.keyword',
            })
                .then((res) => {
                    if (data.elements) res.data.elements = [...data.elements, ...res.data.elements];
                    setData(res.data);
                })
                .catch((err) => {
                    notifyAxiosError(err);
                })
                .finally(() => setGetMoreDataLoading(false));
        } catch (err) {
            console.error(err);
        }
    }, [searchParams, data, ctxt]);

    const toggleSelected = useCallback((id: string, isAdd: boolean) => {
        if (isAdd) {
            setSelectedAssets((prev) => [...prev, id]);
        } else {
            setSelectedAssets((prev) => prev.filter((item) => item !== id));
        }
    }, []);

    const getSortedAssets = useCallback(
        (sortOrder: string | null, sortField: string | null) => {
            const params = searchParams.get('req');
            setSortParams({ sortOrder, sortField });
            if (params) {
                try {
                    const parsedParams = JSON.parse(params);
                    getAssets({
                        tags: parsedParams.tags,
                        pageSize: PAGE_SIZE,
                        freeText: parsedParams.freeText,
                        sortOrder: sortOrder || 'ASC',
                        sortField: sortField || 'file.fullName.keyword',
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        },
        [searchParams],
    );

    const getQuery = useCallback(() => {
        const params = searchParams.get('req');
        if (params) {
            try {
                const parsedParams = JSON.parse(params);
                return {
                    tags: parsedParams.tags,
                    pageSize: PAGE_SIZE,
                    freeText: parsedParams.freeText,
                    sortOrder: 'DESC',
                    sortField: 'audit.createdOn',
                };
            } catch (err) {}
        }
        return {
            pageSize: PAGE_SIZE,
            sortOrder: 'DESC',
            sortField: 'audit.createdOn',
        };
    }, [searchParams]);

    const getSortedSmartBrowsing = useCallback(
        (sortOrder: string | null, sortField: string | null) => {
            const params = searchParams.get('req');
            setSortParamsSmartBrowsing({ sortOrder, sortField });
            if (params) {
                try {
                    const parsedParams = JSON.parse(params);
                    getFolders({
                        aggregationId: parsedParams.aggregationId || '0',
                        tags: parsedParams.tags || [],
                        pageSize: PAGE_SIZE,
                        sortingTags: sortField
                            ? [
                                  {
                                      field: sortField,
                                      order: sortOrder || 'DESC',
                                  },
                              ]
                            : undefined,
                        includeNotSet: includeNotSet,
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        },
        [searchParams, includeNotSet],
    );

    const getIncludeNotSetSmartBrowsing = useCallback(
        (includeNotSet: boolean) => {
            const params = searchParams.get('req');
            setIncludeNotSet(includeNotSet);
            if (params) {
                try {
                    const parsedParams = JSON.parse(params);
                    getFolders({
                        aggregationId: parsedParams.aggregationId || '0',
                        tags: parsedParams.tags || [],
                        pageSize: PAGE_SIZE,
                        sortingTags: sortParamsSmartBrowsing?.sortField
                            ? [
                                  {
                                      field: sortParamsSmartBrowsing?.sortField,
                                      order: sortParamsSmartBrowsing?.sortOrder || 'DESC',
                                  },
                              ]
                            : undefined,
                        includeNotSet: includeNotSet,
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        },
        [searchParams, sortParamsSmartBrowsing],
    );

    return (
        <div onClick={() => setSearchModalOpen(false)}>
            {previewedAsset && (
                <AssetPreview
                    previewedAsset={previewedAsset}
                    onClose={() => setPreviewedAsset(null)}
                    assets={data?.elements || []}
                    toggleSelected={toggleSelected}
                    setIsDownloadAssetModalOpen={setIsDownloadAssetModalOpen}
                />
            )}
            <ShareModal
                setModalState={setShareModalState}
                isModalOpen={isShareModalOpen}
                selectedAssets={selectedAssets}
                query={selectedAssets.length > 0 ? undefined : getQuery()}
                totalResults={selectedAssets.length > 0 ? undefined : data?.totalResults}
            />
            <DownloadAssetsModal
                onDismiss={() => setIsDownloadAssetModalOpen(false)}
                isOpen={isDownloadAssetModalOpen}
                assetsIds={
                    data?.elements
                        ?.filter(
                            (e) =>
                                selectedAssets.includes(e.id) &&
                                (e.resourceType === 'image' || e.resourceType === 'video'),
                        )
                        .map((sa) => sa.id) ?? []
                }
                imagesAreSelected={
                    !!data?.elements?.find(
                        (e) => selectedAssets.includes(e.id) && e.resourceType === 'image',
                    ) || selectedAssets.length === 0
                }
                videosAreSelected={
                    !!data?.elements?.find(
                        (e) => selectedAssets.includes(e.id) && e.resourceType === 'video',
                    ) || selectedAssets.length === 0
                }
                query={selectedAssets.length > 0 ? undefined : getQuery()}
                totalResults={selectedAssets.length > 0 ? undefined : data?.totalResults}
            />
            <MassiveDownloadModal
                isOpen={isMassiveDownloadAssetModalOpen}
                onDismiss={() => setIsMassiveDownloadModalOpen(false)}
                assetIds={
                    data?.elements
                        ?.filter(
                            (e) =>
                                selectedAssets.includes(e.id) &&
                                (e.resourceType === 'image' || e.resourceType === 'video'),
                        )
                        .map((sa) => sa.id) ?? []
                }
                query={selectedAssets.length > 0 ? undefined : getQuery()}
            />

            <HomeTabs selectedTab={selectedTab} handleChange={handleTabChange} tabs={HOME_TABS} />
            {data && (
                <div className="px-[16px] md:px-[32px] py-[7px] md:py-[14px]">
                    <BreadCrumb data={data} />
                    <h1 className="font-bold text-[28px] leading-[32.2px] text-gray-10">
                        {data.title}
                    </h1>
                    <Filters
                        data={data}
                        isBigImg={isBigImg}
                        setBigImg={setBigImg}
                        hideTags={hideTags}
                        setHideTags={setHideTags}
                        selectedAssets={selectedAssets}
                        getSortedAssets={getSortedAssets}
                        getSortedSmartBrowsing={getSortedSmartBrowsing}
                        getIncludeNotSetSmartBrowsing={getIncludeNotSetSmartBrowsing}
                        setSelectedAssets={setSelectedAssets}
                        isSearchModalOpen={isSearchModalOpen}
                        setShareModalState={setShareModalState}
                        setSearchModalOpen={setSearchModalOpen}
                        setIsDownloadAssetModalOpen={setIsDownloadAssetModalOpen}
                        setIsMassiveDownloadAssetModalOpen={setIsMassiveDownloadModalOpen}
                        includeNotSet={includeNotSet}
                    />
                    {!ctxt?.loaderIsActive && (
                        <>
                            <InfiniteScroll
                                dataLength={
                                    data?.elements?.length || data.smartFolders?.length || 0
                                } //This is important field to render the next data
                                next={data.elements ? getMoreAssets : getMoreFolders}
                                hasMore={!!data.hasMore}
                                loader={
                                    <div className="flex gap-x-2 justify-center items-center bg-gray-04 w-fit text-white mx-auto rounded px-6 py-2">
                                        <CircularProgress size={15} color="inherit" />
                                        Loading...
                                    </div>
                                }
                            >
                                <div
                                    className={`pb-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 4xl:grid-cols-6 gap-6 ${
                                        !isBigImg && 'md:grid-cols-3'
                                    }`}
                                >
                                    {data.smartFolders &&
                                        data.smartFolders.map((folder, idx) => (
                                            <Folder
                                                key={`${folder.nextAggreationId}_${idx}`}
                                                folder={folder}
                                                hideTags={hideTags}
                                                isBigImg={isBigImg}
                                            />
                                        ))}
                                    {data.elements &&
                                        data.elements.map((element) => (
                                            <Asset
                                                key={element.id}
                                                element={element}
                                                isBigImg={isBigImg}
                                                hideTags={hideTags}
                                                setPreviewedAsset={setPreviewedAsset}
                                                selectedAssets={selectedAssets}
                                                toggleSelected={toggleSelected}
                                                allAssetsAreSelected={selectAllAssets}
                                                setIsDownloadAssetModalOpen={
                                                    setIsDownloadAssetModalOpen
                                                }
                                            />
                                        ))}
                                </div>
                            </InfiniteScroll>

                            {!getMoreDataLoading && !data.hasMore && data.title !== 'Home' && (
                                <div className="flex justify-center my-6 text-gray-04">
                                    {data?.elements?.length === 0
                                        ? 'No Result Found'
                                        : 'No More Data '}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Home;
