export const ROUTES = {
    HOME: '',
    ALBUMS: '/albums',
    ALBUM_DETAIL: '/albums/:id',
};

export const HOME_TABS = [
    { value: 'smartBrowsing', label: 'Smart Browsing' },
    { value: 'freeBrowsing', label: 'Free Browsing' },
    { value: 'albums', label: 'Albums' },
];

export const SORT_OPTIONS = [
    {
        field: 'file.fullName.keyword',
        label: 'File name',
    },
    {
        field: 'audit.createdOn',
        label: 'Created on',
    },
    {
        field: 'originalBytes',
        label: 'Original size',
    },
];

export const BaseUrl = process.env.REACT_APP_BASE_URL;

export const PAGE_SIZE = 50;

export const MAX_ASSET_DOWNLOADABLE = 10000;
export const MAX_ASSET_ADD_TO_ALBUM = 1000;
export const MAX_ASSET_REMOVE_FROM_ALBUM = 10000;
