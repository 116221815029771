import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';
import { IFolder } from '../types';

interface IData {
    folderId: string;
}

export const getFolder = async (data: IData, cancelToken: any) => {
    const auth = await getAuthHead();
    return await axios.post<IFolder>(`${BaseUrl}SmartBrowsing/GetFolder`, data, {
        ...auth,
        cancelToken: cancelToken.token,
    });
};
