import { AdvancedVideo, lazyload, responsive } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import React, { useRef } from 'react';

import { useIsInViewport } from '../../hooks/useInViewport';

const AssetVideo = ({
    cloudinary,
    height,
    extension,
    storageTypeId,
}: {
    cloudinary?: any;
    height?: any;
    extension?: string;
    storageTypeId: string;
}) => {
    const videoRef = useRef(null);

    const videoIsInViewport = useIsInViewport(videoRef);

    if (!cloudinary) {
        return <div>{'Video not found'}</div>;
    }

    if (storageTypeId === 'AZ') {
        return (
            <video
                style={{ height: '100%' }}
                controls
                controlsList="nodownload"
                preload="metadata"
                height={height}
                ref={videoRef}
            >
                {videoIsInViewport && (
                    <source
                        src={'https:' + cloudinary?.urlPrefix + cloudinary?.publicId}
                        type={'video/' + extension}
                    />
                )}
            </video>
        );
    }

    const cld = new Cloudinary({
        cloud: {
            cloudName: cloudinary?.cloudName,
        },
    });
    const assetVideo = cld.video(cloudinary?.publicId);
    assetVideo.setVersion(cloudinary?.version);
    assetVideo.quality('auto');
    const poster = 'https:' + cloudinary?.urlPrefix + cloudinary?.publicId + '.jpg';

    return (
        <AdvancedVideo
            style={{ height: '100%' }}
            cldVid={assetVideo}
            poster={poster}
            controls
            preload="metadata"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            height={height}
            plugins={[lazyload(), responsive()]}
        />
    );
};

export default AssetVideo;
