import React, { useEffect, useRef, useState } from 'react';

import { useWindowSize } from '../../hooks/windowSize';
import NotFoundImg from '../../images/image-not-found.png';

const Canvas = ({
    asset,
    zoom,
    highlightPeople,
}: {
    asset: any;
    zoom: number;
    highlightPeople: boolean;
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const windowSize = useWindowSize();
    const [mousePosition, setMousePosition] = useState<number[]>([]);
    const [mouseDown, setMouseDown] = useState(false);

    const drawPos = useRef([0, 0]);
    const delta = useRef([0, 0]);

    const render = (
        img: HTMLImageElement,
        canvas: HTMLCanvasElement,
        context: CanvasRenderingContext2D,
        zoom: number,
        highlightPeople: boolean,
    ) => {
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Once the image is loaded, we will get the width & height of the image
        const loadedImageWidth = img.width;
        const loadedImageHeight = img.height;

        // get the scale
        // it is the min of the 2 ratios
        let scaleFactor = Math.min(
            canvas.width / loadedImageWidth,
            canvas.height / loadedImageHeight,
        );

        if (zoom === 1) {
            scaleFactor = 1.3;
        }
        if (zoom === 2) {
            scaleFactor = 1.5;
        } else if (zoom === 3) {
            scaleFactor = 2;
        }

        // Finding the new width and height based on the scale factor
        const newWidth = loadedImageWidth * scaleFactor;
        const newHeight = loadedImageHeight * scaleFactor;

        // get the top left position of the image
        // in order to center the image within the canvas
        const x = drawPos.current[0] - newWidth / 2;
        const y = drawPos.current[1] - newHeight / 2;

        // When drawing the image, we have to scale down the image
        // width and height in order to fit within the canvas
        context.drawImage(img, x, y, newWidth, newHeight);

        if (!asset.cloudinary) return;

        const faceScaleFactor = newWidth / asset.cloudinary.width;

        if (asset.faces && highlightPeople) {
            for (const face of asset.faces) {
                const faceMargin = 40;

                const cornerLeft = x + (face.cornerLeft - faceMargin / 2) * faceScaleFactor;
                const cornerTop = y + (face.cornerTop - faceMargin / 2) * faceScaleFactor;
                const width = (face.width + faceMargin / 2) * faceScaleFactor;
                const height = (face.height + faceMargin / 2) * faceScaleFactor;

                context.beginPath();
                context.strokeStyle = 'white';
                context.lineWidth = 4;
                context.roundRect(cornerLeft, cornerTop, width, height, 10);
                context.stroke();

                const gap = 8;
                const fontSize = 16;
                const txt = face.personName?.toUpperCase();

                context.font = fontSize + 'px Arial';

                const txtWidth = context.measureText(txt).width;

                context.globalAlpha = 0.5;
                context.fillStyle = 'black';
                context.strokeStyle = 'black';
                context.lineWidth = 1;

                const xRectTxt = cornerLeft + width / 2 - txtWidth / 2;

                context.beginPath();
                context.roundRect(
                    xRectTxt - 4,
                    cornerTop + height + gap,
                    txtWidth + 16,
                    fontSize + 6,
                    10,
                );
                context.fill();
                context.stroke();
                context.globalAlpha = 1;

                context.fillStyle = 'white';
                context.fillText(txt, xRectTxt + 4, cornerTop + height + gap + fontSize + 2);
            }
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        const h = 'h_1400';
        const DRAW_POS = [canvas.width / 2, canvas.height / 2];

        //Our first draw
        const url = `https:${asset.cloudinary?.urlPrefix}${
            asset.file?.extension === 'pdf' ? 'f_auto' : 'c_thumb'
        },q_auto,${h}/v${asset.cloudinary?.version}/${asset.cloudinary?.publicId}`;

        if (zoom === 0) {
            drawPos.current = DRAW_POS;
        } else if (mouseDown) {
            drawPos.current = [
                drawPos.current[0] + delta.current[0],
                drawPos.current[1] + delta.current[1],
            ];
        }

        const img = new Image();
        img.onload = () => {
            render(img, canvas, context, zoom, highlightPeople);
        };

        img.onerror = ({ currentTarget }: any) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NotFoundImg;
        };

        img.src = url;
    }, [windowSize, zoom, mousePosition, mouseDown, highlightPeople]);

    return windowSize?.width && windowSize?.height ? (
        <canvas
            ref={canvasRef}
            className="mt-[50px]"
            width={windowSize.width - 100}
            height={windowSize.height - 200}
            onPointerDown={(e) => e.stopPropagation()}
            style={{
                cursor: zoom > 0 ? 'grab' : 'auto',
            }}
            onMouseDown={() => {
                if (zoom > 0) setMouseDown(true);
            }}
            onMouseUp={() => {
                if (zoom > 0) {
                    setMouseDown(false);
                    setMousePosition([]);
                }
            }}
            onMouseMove={(ev) => {
                if (mouseDown) {
                    delta.current =
                        mousePosition.length > 0
                            ? [ev.clientX - mousePosition[0], ev.clientY - mousePosition[1]]
                            : [0, 0];
                    setMousePosition([ev.clientX, ev.clientY]);
                }
            }}
        />
    ) : null;
};

export default Canvas;
