import { mergeStyleSets, Modal } from '@fluentui/react';
import React from 'react';

interface IProps {
    isModalOpen: boolean;
    onDismiss?: () => void;
    children?: any;
}
const contentStyles = mergeStyleSets({
    container: {
        position: 'relative',
        backGroundColor: 'rgba(0,0,0,0.48)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        overflow: 'auto',
        width: '90%',
        maxWidth: '825px',
        padding: '50px 40px',
    },
    paperFullWidth: {
        overflowY: 'visible',
    },
    dialogContentRoot: {
        overflowY: 'visible',
    },
});

export const BasicModal: React.FC<IProps> = ({ onDismiss, isModalOpen, children }) => {
    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={() => onDismiss?.()}
            containerClassName={contentStyles.container}
            isBlocking={true}
        >
            {children}
        </Modal>
    );
};
