import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';
import { ITag } from '../types';

interface IData {
    pageSize: number;
    nextPaginationInfo?: number[];
    freeText?: string;
    tags: ITag[];
    sortOrder?: string;
    sortField?: string;
}

export const getSmartBrowsingAssets = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post(`${BaseUrl}SmartBrowsing/GetAssets`, data, auth);
};
