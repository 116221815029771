import React, { FunctionComponent, useEffect, useState } from 'react';

import { allApis } from '../store/rtk';
import { DisclaimerObject } from '../store/rtk/allApis';
import CheckboxWithLabel from './CheckboxWithLabel';

type DisclaimerModalProps = {
    onAccept?: () => void;
    disclaimerType: string;
    disclaimer?: DisclaimerObject;
};

const DisclaimerModal: FunctionComponent<DisclaimerModalProps> = ({
    onAccept,
    disclaimerType,
    disclaimer,
}) => {
    const [checkboxes, setCheckboxes] = useState<
        { text: string; value: boolean; required: boolean }[] | undefined
    >([]);

    const [acceptFailed, setAcceptFailed] = useState(false);

    const [insertDisclaimerApi, resultInsertDisclaimer] =
        allApis.usePostDisclaimerInsertDisclaimerLogMutation();
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return function cleanup() {
            document.body.style.overflow = 'visible';
        };
    }, []);

    const onInternalAccept = async () => {
        setAcceptFailed(false);
        try {
            const result = await insertDisclaimerApi({ disclaimerType: disclaimerType }).unwrap();
            if (result.result === true) {
                onAccept?.();
            } else {
                setAcceptFailed(true);
            }
        } catch (err) {
            setAcceptFailed(true);
        }
    };

    useEffect(() => {
        setCheckboxes(
            disclaimer?.checkBoxes?.map((c) => ({
                text: c.disclaimerQuestionText ?? '',
                value: false,
                required: c.isRequired ?? false,
            })),
        );
    }, [disclaimer]);

    return (
        <div className="fixed inset-0 h-full w-full flex items-center justify-center z-[10001]">
            <div className="absolute inset-0 h-full w-full bg-black opacity-75 z-10"></div>

            <div
                className={
                    'relative bg-gray-200 z-20 max-h-[80vh] max-w-[90%] lg:max-w-[50%] md:min-w-[350px] p-8 overflow-auto'
                }
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: disclaimer?.disclaimerText ?? '',
                    }}
                ></div>
                {!disclaimer && 'Failed to load disclaimer. Please try to reload the page.'}
                {checkboxes &&
                    checkboxes.map((c, index) => (
                        <CheckboxWithLabel
                            key={index}
                            label={c.text}
                            value={c.value}
                            onToggle={() => {
                                setCheckboxes((oldCheckbox) => {
                                    if (oldCheckbox) {
                                        const newCheckbox = [...oldCheckbox];
                                        newCheckbox[index].value = !c.value;
                                        return newCheckbox;
                                    }

                                    return oldCheckbox;
                                });
                            }}
                        />
                    ))}

                {acceptFailed && (
                    <div className="text-red-600 py-2">
                        Error in accepting the disclaimer. Please try again.
                    </div>
                )}
                <div className="flex flex-row justify-end pt-8">
                    <button
                        onClick={onInternalAccept}
                        className="bg-black disabled:opacity-30 flex flex-row gap-2 items-center px-4 py-2 text-[14px] text-white"
                        disabled={
                            checkboxes?.filter((c) => c.required).some((c) => !c.value) ||
                            resultInsertDisclaimer.isLoading ||
                            !disclaimer
                        }
                    >
                        ACCEPT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DisclaimerModal;
