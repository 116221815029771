import React from 'react';
import { useNavigate } from 'react-router-dom';

import CategorizedTag from '../../components/CategorizedTag';
import { ReactComponent as TypeFolderIcon } from '../../images/icons/type-folder.svg';
import notFoundImg from '../../images/image-not-found.png';
import { ISmartFolder } from '../../types';
import { encodeURLParam, formatNumber } from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../utils/extendedGlobal';

interface IProps {
    folder: ISmartFolder;
    hideTags: boolean;
    isBigImg: boolean;
}
const Folder: React.FC<IProps> = ({ folder, hideTags, isBigImg }) => {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => {
                if (folder.title) {
                    sendGoogleAnalyticsEvent('folder_' + folder.title + '_visited');
                } else {
                    const tags = folder.tags.map(
                        (x) => 'Category: ' + x.category + ', Value: ' + x.value[0],
                    );
                    const additionalInfo = tags.join(';');
                    sendGoogleAnalyticsEvent('subFolder_visited', additionalInfo);
                }

                const encodedParam = encodeURLParam({
                    aggregationId: folder.nextAggreationId,
                    tags: folder.tags.map((tag) => ({
                        value: tag.value,
                        category: tag.category,
                        hidden: tag.hidden,
                        readOnly: tag.readOnly,
                    })),
                });
                navigate(`?req=${encodedParam}`);
            }}
            className={`w-full bg-white mx-auto pt-5 pb-4 px-[18px] border border-gray-02 rounded text-gray-1 cursor-pointer group hover:border-gray-03 duration-150 ${
                isBigImg && 'col-span-2'
            }`}
        >
            <div className="flex items-center">
                <div className="flex items-center flex-wrap">
                    {folder.title && <h3 className="font-bold text-lg mr-2">{folder.title}</h3>}
                    {(folder.totalAssets || folder.totalAssets === 0) && (
                        <p className="text-[12px] leading-[18px] ml-1">{`(${formatNumber(
                            folder.totalAssets,
                        )} assets)`}</p>
                    )}
                </div>
                <div className="flex items-center gap-x-2 px-3 py-[7px] bg-gray-01 text-sm rounded-[44px] ml-auto">
                    <TypeFolderIcon className="fill-gray-06 w-5 h-5" />
                    <p className="capitalize text-gray-05">folder</p>
                </div>
            </div>
            <div
                className={`relative w-full overflow-hidden duration-150 mt-4 mb-3 ${
                    isBigImg ? 'h-[420px]' : 'h-[336px]'
                }`}
            >
                <img
                    src={folder.imageUrl}
                    alt="Folder photo"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = notFoundImg;
                    }}
                    className="w-full h-full object-contain object-center opacity-80 group-hover:opacity-100 duration-200 group-hover:scale-105"
                />
            </div>
            {!hideTags && (
                <div className="space-y-2">
                    {folder?.tags
                        ?.filter((tag) => !tag.hidden)
                        ?.map((tag, idx) => <CategorizedTag tag={tag} key={idx} />)}
                    {folder?.viewOnlyTags?.map((tag, idx) => (
                        <CategorizedTag tag={tag} key={idx} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Folder;
