import { useEffect, useState } from 'react';

const useScrollPosition = (scrollSize: number) => {
    const [wasScrolled, setScrolled] = useState(false);

    useEffect(() => {
        const updatePosition = () => {
            setScrolled(window.pageYOffset >= scrollSize);
        };
        window.addEventListener('scroll', updatePosition);
        updatePosition();
        return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    return wasScrolled;
};

export default useScrollPosition;
