import { CircularProgress } from '@mui/material';
import React from 'react';

interface IProps {
    onClick?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    children?: React.ReactNode;
    fullWidth?: boolean;
}

const DefaultButton: React.FC<IProps> = ({ onClick, disabled, isLoading, children, fullWidth }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={
                'py-4 px-7 md:px-14 text-sm font-semibold bg-transparent border border-[#EDEDED] hover:border-gray-03 duration-100 disabled:opacity-40 disabled:cursor-not-allowed disabled:hover:border-[#EDEDED]' +
                (fullWidth ? 'w-full' : '')
            }
        >
            {isLoading && <CircularProgress color="inherit" className="mr-1" size={20} />}
            {children}
        </button>
    );
};

export default DefaultButton;
