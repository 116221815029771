import React, { useState } from 'react';

import { allApis } from '../store/rtk';
import DisclaimerModal from './DisclaimerModal';

export interface IProps {
    children: string | JSX.Element | JSX.Element[];
}
export const AlbumDetailDisclaimer: React.FC<IProps> = ({ children }) => {
    const { data: configurationParameter, isLoading } =
        allApis.useGetDisclaimerGetDisclaimersQuery();
    const disclaimer = configurationParameter?.find(
        (d) => d.disclaimerName === 'UI/DigitalArchive/AlbumDisclaimer',
    );

    const [disclaimerAccepted, setDisclaimerAccepted] = useState<boolean | null>(null);

    return (
        <div className="min-h-screen w-full">
            {children}
            {!disclaimer?.alreadyAccepted && disclaimerAccepted !== true && !isLoading && (
                <DisclaimerModal
                    disclaimer={disclaimer}
                    disclaimerType={'UI/DigitalArchive/AlbumDisclaimer'}
                    onAccept={() => {
                        setDisclaimerAccepted(true);
                    }}
                />
            )}
        </div>
    );
};
