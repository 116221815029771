import React, { useEffect, useState } from 'react';

import ActionModal from '../../components/ActionModal';
import { removeAssetFromAlbum } from '../../services/removeAssetFromAlbum';
import { notifyAxiosError } from '../../utils';

interface IProps {
    isModalOpen: boolean;
    onDismiss: (success: boolean) => void;
    assetsIds: string[];
    albumId: string;
}

export const RemoveImageFromAlbumModal: React.FC<IProps> = ({
    onDismiss,
    isModalOpen,
    albumId,
    assetsIds,
}) => {
    const [isExecutingAction, setIsExecutingAction] = useState(false);
    const [success, setSucess] = useState(true);

    useEffect(() => {
        if (isModalOpen) {
            setSucess(false);
        }
    }, [isModalOpen]);

    const onPrimaryActionCallback = () => {
        setIsExecutingAction(true);
        removeAssetFromAlbum({ albumId: albumId, assetsId: assetsIds })
            .then(() => setSucess(true))
            .catch((err) => notifyAxiosError(err))
            .finally(() => {
                setIsExecutingAction(false);
            });
    };

    return (
        <ActionModal
            isModalOpen={isModalOpen}
            onDismiss={() => onDismiss(success)}
            primaryActionTitle="Remove images"
            isExecutingAction={isExecutingAction}
            onPrimaryActionCallback={onPrimaryActionCallback}
            title="Remove Images"
            showPrimaryButton={!success}
        >
            {!success ? (
                <div>
                    Are you sure you want to remove {assetsIds.length} images from the current
                    album?
                </div>
            ) : (
                <div>
                    Request to remove images from album sent. The operation will be completed in
                    background.
                </div>
            )}
        </ActionModal>
    );
};

export default RemoveImageFromAlbumModal;
