import React from 'react';

import { ReactComponent as CheckIcon } from '../images/icons/check.svg';

interface IProps {
    value: boolean;
    onToggle: () => void;
    disabled: boolean;
    isOpacityZero?: boolean;
}

const Checkbox: React.FC<IProps> = ({ value, onToggle, disabled, isOpacityZero }) => {
    return (
        <div
            className={`duration-100 group-hover:opacity-100 ${isOpacityZero && 'opacity-0'} ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            } `}
            onClick={() => {
                if (!disabled) {
                    onToggle();
                }
            }}
        >
            <div
                className={`w-[20px] h-[20px] border-[0.5px] bg-white border-gray-06 rounded-[2px] flex justify-center items-center`}
            >
                <CheckIcon className={value ? 'fill-gray-06' : 'fill-transparent'} />
            </div>
        </div>
    );
};

export default Checkbox;
