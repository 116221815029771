import { PublicClientApplication } from '@azure/msal-browser';

import { loginRequest, msalConfig } from './config';

export const msalInstance = new PublicClientApplication(msalConfig);

export const getAuthHead = async () => {
    try {
        const accounts = msalInstance.getAllAccounts();

        if (!accounts || accounts.length === 0) return {};

        const res = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        });

        if (!res.accessToken) return {};

        return {
            headers: { authorization: `Bearer ${res.accessToken}` },
        };
    } catch (err) {
        return {};
    }
};
