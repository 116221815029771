import { IconButton, Spinner } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';

type EditFieldProps = {
    value?: string;
    onSave: (newValue: string) => void;
    disabled?: boolean;
    editModeError?: string;
    textClassName?: string;
    loading?: boolean;
    placeHolder?: string;
    showEditButton?: boolean;
};

const EditField: FunctionComponent<EditFieldProps> = ({
    value,
    onSave,
    disabled,
    editModeError,
    textClassName,
    loading,
    placeHolder,
    showEditButton = true,
}) => {
    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState('');

    useEffect(() => {
        setNewValue(value ?? '');
    }, [value]);

    return (
        <div className="flex items-center gap-2 h-[40px] ">
            {!editMode ? (
                <>
                    <div className={textClassName}>{value}</div>
                    {!loading && showEditButton && (
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            title="Edit"
                            ariaLabel="Edit"
                            onClick={() => {
                                setEditMode(true);
                            }}
                        />
                    )}
                    {loading && <Spinner />}
                </>
            ) : (
                <div className="flex items-center gap-2 relative h-full">
                    <input
                        type="text"
                        className="placeholder-[#B9B9B9] w-full h-full md:w-[300px] px-3 focus:outline-none border border-[#EDEDED] text-sm"
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        placeholder={placeHolder}
                        tabIndex={0}
                        autoFocus={editMode}
                    />

                    <IconButton
                        iconProps={{ iconName: 'Save' }}
                        title="Save"
                        ariaLabel="Save"
                        disabled={disabled || !newValue}
                        onClick={() => {
                            onSave(newValue);
                            setEditMode(false);
                        }}
                    />
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        title="Cancel"
                        ariaLabel="Cancel"
                        onClick={() => setEditMode(false)}
                    />

                    {editModeError && editMode && (
                        <div className="absolute text-xs top-10 text-red-500">{editModeError}</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default EditField;
