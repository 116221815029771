import React from 'react';

import { ReactComponent as SearchIcon } from '../images/icons/search.svg';

interface IProps {
    value?: string;
    onChange: (newValue: string) => void;
}

const SearchBox: React.FC<IProps> = ({ value, onChange }) => {
    return (
        <div className={`relative w-full`}>
            <input
                value={value ?? ''}
                onChange={(event) => {
                    onChange(event.target.value);
                }}
                type="text"
                placeholder="Search"
                className="p-[9px] pl-[40px] w-full outline-none tracking-[0.16px] text-gray-05 text-sm bg-gray-01 border border-transparent rounded group-hover:text-gray-08 group-hover:border-gray-03 focus:border-gray-07 focus:text-gray-1 focus:group-hover::text-gray-1 focus:group-hover:border-gray-07 duration-150"
            />
            <SearchIcon className="absolute top-3 left-4" />
        </div>
    );
};

export default SearchBox;
