import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';

interface IData {
    albumId: string;
    albumDs: string;
}

interface Output {
    Edited: boolean;
}

export const editAlbum = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post<Output>(`${BaseUrl}Album/EditAlbum`, data, auth);
};
