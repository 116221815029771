import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';

interface IData {
    albumId: string;
    assetsId: string[];
}

interface Output {
    Removed: boolean;
}

export const removeAssetFromAlbum = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post<Output>(`${BaseUrl}Album/RemoveAssetFromAlbum`, data, auth);
};
