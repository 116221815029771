import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';
import { ITag } from '../types';

interface IData {
    field: string;
    freeText?: string;
    filterFreeText?: string;
    tags: ITag[];
}

export const getFilterValues = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post<{ id: string; value: 'string' }[]>(
        `${BaseUrl}SmartBrowsing/GetFilterValues`,
        data,
        auth,
    );
};
