import 'react-toastify/dist/ReactToastify.css';

import { InteractionType } from '@azure/msal-browser';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from '@azure/msal-react';
import { Icon, Spinner, SpinnerSize } from '@fluentui/react';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Navbar from '../common/navbar';
import { AlbumDetailDisclaimer } from '../components/AlbumDetailDisclaimer';
import { LoginDisclaimer } from '../components/LoginDisclaimer';
import { ROUTES } from '../consts';
import { loginRequest } from '../helpers/auth/config';
import AlbumDetail from '../pages/albumDetail';
import Albums from '../pages/albums';
import Home from '../pages/home';

export interface IProvider {
    loaderIsActive: boolean;
    setLoaderIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = React.createContext<IProvider | null>(null);

function Protected({ children }: { children: React.ReactNode }) {
    const { error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    return (
        <>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {!error && (
                    <div className="p-4 flex flex-row items-center justify-center">
                        <Spinner size={SpinnerSize.xSmall} />
                        <span className="text-base pl-2">
                            We are redirecting to login. Please wait.
                        </span>
                    </div>
                )}
                {error && (
                    <div className="pt-32 flex justify-center">
                        <div style={{ maxWidth: 700 }} className="p-2 m-2">
                            <div className="flex flex-row items-center justify-center">
                                <Icon iconName="Warning" />
                                <span className="pl-2 font-medium text-lg">
                                    We are sorry but there were some errors in the authentication
                                    process.
                                </span>
                            </div>
                            <div className="text-red-800 pt-4 text-base">
                                {false ? (
                                    <span>
                                        Error code: <b>{error?.errorCode}</b>
                                    </span>
                                ) : (
                                    error.errorMessage
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </UnauthenticatedTemplate>
        </>
    );
}

export const Router: React.FC = () => {
    const [loaderIsActive, setLoaderIsActive] = useState(false);

    return (
        <LoaderContext.Provider value={{ loaderIsActive, setLoaderIsActive }}>
            <LoadingOverlay
                styles={{
                    wrapper: {
                        minHeight: '100vh',
                        backgroundColor: '#F7F7F7',
                    },
                    overlay: {
                        width: '100%',
                        height: '100vh',
                        zIndex: 10001,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                    },
                }}
                active={loaderIsActive}
                spinner
                text="Loading..."
                className="z-10"
            >
                <Routes>
                    <Route
                        path={ROUTES.HOME}
                        element={
                            <Protected>
                                <LoginDisclaimer>
                                    <Navbar />
                                    <Home />
                                </LoginDisclaimer>
                            </Protected>
                        }
                    />
                    <Route
                        path={ROUTES.ALBUMS}
                        element={
                            <Protected>
                                <LoginDisclaimer>
                                    <Navbar />
                                    <Albums />
                                </LoginDisclaimer>
                            </Protected>
                        }
                    />
                    <Route
                        path={ROUTES.ALBUM_DETAIL}
                        element={
                            <Protected>
                                <AlbumDetailDisclaimer>
                                    <Navbar />
                                    <AlbumDetail />
                                </AlbumDetailDisclaimer>
                            </Protected>
                        }
                    />
                </Routes>

                <ToastContainer
                    limit={1}
                    autoClose={3000}
                    style={{ zIndex: 9999999, top: 65, right: 25 }}
                />
            </LoadingOverlay>
        </LoaderContext.Provider>
    );
};
