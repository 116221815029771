import { FontIcon, mergeStyleSets, Modal } from '@fluentui/react';
import React, { FunctionComponent, useState } from 'react';

import { MAX_ASSET_DOWNLOADABLE } from '../../consts';
import { allApis } from '../../store/rtk';
import { formatNumber } from '../../utils';
import PrimaryButton from '../PrimaryButton';

const contentStyles = mergeStyleSets({
    container: {
        position: 'relative',
        backGroundColor: 'rgba(0,0,0,0.48)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        overflow: 'auto',
        width: '90%',
        maxWidth: '825px',
        padding: '50px 40px',
    },
    paperFullWidth: {
        overflowY: 'visible',
    },
    dialogContentRoot: {
        overflowY: 'visible',
    },
});

type DownloadAssetsModalProps = {
    assetsIds: string[];
    query?: any;
    albumQuery?: any;
    totalResults?: number;
    videosAreSelected: boolean;
    imagesAreSelected: boolean;
    isOpen: boolean;
    onDismiss: () => void;
};

const DownloadAssetsModal: FunctionComponent<DownloadAssetsModalProps> = ({
    assetsIds,
    videosAreSelected,
    imagesAreSelected,
    totalResults,
    isOpen,
    onDismiss,
    query,
    albumQuery,
}) => {
    const [generateArchiveList, generateArchiveListResult] =
        allApis.useLazyPostSmartBrowsingGenerateArchiveListQuery();

    const [clickedZip, setClickedZip] = useState<any[]>([]);

    const download = async (quality: string) => {
        setClickedZip([]);
        if (quality === 'original') {
            await generateArchiveList({
                assetIds: assetsIds,
                searchQuery: query,
                searchQueryAlbum: albumQuery,
            });
        } else if (quality === 'optimized') {
            await generateArchiveList({
                imageQuality: 'auto',
                videoQuality: 'auto',
                assetIds: assetsIds,
                searchQuery: query,
                searchQueryAlbum: albumQuery,
            });
        } else if (quality === 'large') {
            await generateArchiveList({
                imageWidth: 1024,
                imageMode: 'limit',
                videoWidth: 1024,
                videoMode: 'limit',
                assetIds: assetsIds,
                searchQuery: query,
                searchQueryAlbum: albumQuery,
            });
        } else if (quality === 'medium') {
            await generateArchiveList({
                imageWidth: 600,
                imageMode: 'limit',
                videoWidth: 600,
                videoMode: 'limit',
                assetIds: assetsIds,
                searchQuery: query,
                searchQueryAlbum: albumQuery,
            });
        } else if (quality === 'small') {
            await generateArchiveList({
                imageWidth: 175,
                imageMode: 'limit',
                videoWidth: 175,
                videoMode: 'limit',
                assetIds: assetsIds,
                searchQuery: query,
                searchQueryAlbum: albumQuery,
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={() => onDismiss()}
            containerClassName={contentStyles.container}
        >
            <div className="space-y-2">
                <div className="justify-between w-full flex">
                    <div className="flex-grow">
                        <div className="text-xl">
                            {totalResults
                                ? 'Download options - ' + totalResults + ' assets selected'
                                : 'Download options - ' + assetsIds.length + ' assets selected'}
                        </div>
                        <div className="text-xs">
                            Maximum assets downloadable {formatNumber(MAX_ASSET_DOWNLOADABLE)} - Raw
                            file are not available for bulk download
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-300 py-2">
                    {imagesAreSelected || videosAreSelected ? (
                        <div className="pt-2 w-full">
                            <PrimaryButton
                                fullWidth
                                disabled={generateArchiveListResult.isFetching}
                                isLoading={generateArchiveListResult.isFetching}
                                onClick={() => download('original')}
                            >
                                <div>Download Now</div>
                                <FontIcon iconName={'Download'} />
                            </PrimaryButton>
                        </div>
                    ) : (
                        <div className="text-red-600 text-center mt-8">
                            Raw files can&apos;t be downloaded in bulk mode
                        </div>
                    )}
                </div>

                <div>
                    {generateArchiveListResult?.data?.downloadAssets?.map((da, index) => (
                        <div key={index} className="flex flex-row items-center gap-2">
                            <a
                                className={
                                    clickedZip.includes(da.url) ? 'text-green-500' : 'text-blue-500'
                                }
                                href={da.url ?? ''}
                                download
                                onClick={() =>
                                    setClickedZip((oldValue) => oldValue.concat([da.url]))
                                }
                            >
                                {da.description}
                            </a>
                            <span>({da.totalElements} elements)</span>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default DownloadAssetsModal;
