import React from 'react';

interface IProps {
    children?: any;
    isFilterModalOpen: boolean;
}

const FilterContainer: React.FC<IProps> = ({ children, isFilterModalOpen }) => {
    return (
        <div
            onClick={(event) => event.stopPropagation()}
            className={`${
                isFilterModalOpen ? 'translate-x-0' : 'translate-x-[-100%]'
            } p-[10px] w-[50%] min-h-[70vh] min-w-[230px] overflow-y-auto h-screen bg-white rounded fixed top-[54px] z-40 left-0 duration-100 shadow-xl md:min-h-fit md:z-0 md:relative md:p-[10px] md:w-full md:h-fit md:top-0 md:translate-x-0 md:overflow-y-visible md:shadow-none`}
        >
            {children}
        </div>
    );
};

export default FilterContainer;
