import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/auth';
import { ISmartBrowsingResponse, ITag } from '../types';

interface IData {
    tags?: ITag[];
    pageSize: number;
    freeText?: string;
    aggregationId: string;
    nextPaginationInfo?: number[];
    sortingTags?: Array<{ field: string; order: string }>;
    includeNotSet?: boolean;
}

export const getSmartBrowsing = async (data: IData) => {
    const auth = await getAuthHead();
    return await axios.post<ISmartBrowsingResponse>(
        `${BaseUrl}SmartBrowsing/GetSmartBrowsing`,
        data,
        auth,
    );
};
