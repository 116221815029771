import { Icon } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import FiltersPanelButton from '../../components/FiltersPanelButton';
import HideShowTags from '../../components/HideShowTags';
import IconButton from '../../components/IconButton';
import SmallBigViewSwitch from '../../components/SmallBigViewSwitch';
import SortButton from '../../components/SortButton';
import { SORT_OPTIONS } from '../../consts';
import useDataType, {
    DATA_TYPE_FREEBROWSING,
    DATA_TYPE_SMARTBROWSING,
} from '../../hooks/useDataType';
import { ReactComponent as DividerIcon } from '../../images/icons/divider.svg';
import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';
import { ReactComponent as ShareIcon } from '../../images/icons/share.svg';
import { ISelectedSort, ISmartBrowsingResponse, ITag } from '../../types';
import { formatNumber, getFilterCategories } from '../../utils';

type SortOption = { field: string; label: string };

interface IProps {
    hideTags: boolean;
    isBigImg: boolean;
    selectedAssets: string[];
    data: ISmartBrowsingResponse;
    includeNotSet: boolean;
    getSortedAssets: (sortOrder: string | null, sortField: string | null) => void;
    getSortedSmartBrowsing: (sortOrder: string | null, sortField: string | null) => void;
    getIncludeNotSetSmartBrowsing: (includeNotSet: boolean) => void;
    setBigImg: React.Dispatch<React.SetStateAction<boolean>>;
    setHideTags: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAssets: React.Dispatch<React.SetStateAction<string[]>>;
    setSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setShareModalState: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDownloadAssetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMassiveDownloadAssetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Operations: React.FC<IProps> = ({
    data,
    isBigImg,
    hideTags,
    setBigImg,
    setHideTags,
    selectedAssets,
    getSortedAssets,
    getSortedSmartBrowsing,
    setSelectedAssets,
    setSearchModalOpen,
    setShareModalState,
    setIsDownloadAssetModalOpen,
    setIsMassiveDownloadAssetModalOpen,
}) => {
    const [searchParams] = useSearchParams();
    const [selectedSort, setSelectedSort] = useState<ISelectedSort | null>(null);
    const [sortOptions, setSortOptions] = useState<Array<SortOption>>([]);
    const [allVisibileAssetsAreSelected, setAllVisibileAssetsAreSelected] = useState(false);

    const dataType = useDataType(data);

    const onSelectedSortClear = useCallback(() => {
        setSelectedSort(null);

        if (dataType === DATA_TYPE_SMARTBROWSING) {
            getSortedSmartBrowsing(null, null);
        }
        if (dataType === DATA_TYPE_FREEBROWSING) {
            getSortedAssets(null, null);
        }

        close();
    }, [dataType, getSortedSmartBrowsing, getSortedAssets]);

    useEffect(() => {
        setSelectedSort(null);
    }, [searchParams]);

    useEffect(() => {
        if (!selectedSort) return;

        if (dataType === DATA_TYPE_SMARTBROWSING) {
            getSortedSmartBrowsing(selectedSort?.order ?? null, selectedSort?.field ?? null);
        }
        if (dataType === DATA_TYPE_FREEBROWSING) {
            getSortedAssets(selectedSort?.order ?? null, selectedSort?.field ?? null);
        }
    }, [selectedSort]);

    useEffect(() => {
        if (dataType === DATA_TYPE_SMARTBROWSING) {
            setSortOptions(getFilterCategories(data).map((x) => ({ field: x, label: x })));
        }
        if (dataType === DATA_TYPE_FREEBROWSING) {
            setSortOptions(SORT_OPTIONS);
        }
    }, [data, dataType]);

    return (
        <div className="flex my-4">
            <div className="flex flex-col md:flex-row md:flex-wrap md:items-center text-gray-1">
                <FiltersPanelButton
                    onClick={() => {
                        setSearchModalOpen(true);
                    }}
                />
                {data.elements && (
                    <>
                        <p className="text-gray-09 text-sm">
                            Total assets ({formatNumber(data.totalResults)})
                        </p>
                        <DividerIcon className="hidden md:block md:mx-5" />
                        <p className="text-gray-09 text-sm">
                            Assets selected{' '}
                            <span className="min-w-[25px] inline-block">
                                {formatNumber(selectedAssets.length)}
                            </span>
                        </p>
                        <DividerIcon className="hidden md:block md:mx-5" />
                        <div className="flex flex-wrap items-center">
                            <CheckboxWithLabel
                                value={allVisibileAssetsAreSelected}
                                onToggle={() => {
                                    if (allVisibileAssetsAreSelected) {
                                        setSelectedAssets([]);
                                    } else {
                                        setSelectedAssets(data.elements?.map((x) => x.id) ?? []);
                                    }
                                    setAllVisibileAssetsAreSelected((prev) => !prev);
                                }}
                                label="Select all visible"
                            />
                            <DividerIcon className="mx-2 md:mx-6" />
                            <IconButton
                                onClick={() => setShareModalState(true)}
                                title={
                                    selectedAssets.length > 0
                                        ? 'Add selected to album'
                                        : 'Add all to album'
                                }
                            >
                                <ShareIcon />
                            </IconButton>

                            <DividerIcon className="mx-2 md:mx-6" />
                            <IconButton
                                title="Massive download"
                                onClick={() => setIsMassiveDownloadAssetModalOpen(true)}
                            >
                                <DownloadIcon />
                            </IconButton>
                            <DividerIcon className="mx-2 md:mx-6" />
                        </div>
                    </>
                )}
            </div>
            <div className="ml-auto flex gap-x-[10px] items-center">
                {(dataType === DATA_TYPE_SMARTBROWSING || dataType === DATA_TYPE_FREEBROWSING) && (
                    <SortButton
                        selectedSort={selectedSort}
                        setSelectedSort={(obj) => setSelectedSort(obj)}
                        onClear={onSelectedSortClear}
                        sortOptions={sortOptions}
                    />
                )}
                {(data.smartFolders?.find((f) => f.tags?.find((t) => !t.hidden)) ||
                    data.elements?.find((f) => f.tags?.find((t: ITag) => !t.hidden))) && (
                    <HideShowTags
                        hideTags={hideTags}
                        onClick={() => setHideTags((prev) => !prev)}
                    />
                )}

                <SmallBigViewSwitch
                    isBigImg={isBigImg}
                    setBigImg={(newValue) => setBigImg(newValue)}
                />
            </div>
        </div>
    );
};

export default Operations;
