import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { BaseUrl } from '../../consts';
import { getAuthHead } from '../../helpers/auth';
import { rtkBaseQueryFactory } from './rtkBaseQuery';

export const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: BaseUrl,
});
axiosInstance.interceptors.request.use(async (config) => {
    const header = await getAuthHead();

    if (header?.headers) {
        config.headers.set('Authorization', header.headers.authorization);
    }

    return config;
});
axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        let message = 'Error';
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data.ExceptionMessage) {
                message = error.response.data.ExceptionMessage;
            }
        } else if (typeof error === 'string') {
            message = error;
        } else {
            // Something happened in setting up the request that triggered an Error
            message = error.message;
        }
        toast.error(message);
        return Promise.reject(error);
    },
);

export const emptyApi = createApi({
    reducerPath: 'allApis',
    baseQuery: rtkBaseQueryFactory({
        getAxiosInstance: async () => {
            return axiosInstance;
        },
    }),
    endpoints: () => ({}),
});
