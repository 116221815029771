import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ClearFilterButton from '../../components/ClearFilterButton';
import FilterContainer from '../../components/FilterContainer';
import FilterSelect from '../../components/filterSelect';
import SearchBox from '../../components/SearchBox';
import { HOME_TABS } from '../../consts';
import { CommandDataContext } from '../../context/CommandDataContext';
import { useDebounce } from '../../hooks/useDebouce';
import { ReactComponent as ClearIcon } from '../../images/icons/clear.svg';
import { getFiltersList } from '../../services/getFiltersList';
import { getFilterValues } from '../../services/getFilterValues';
import { ISmartBrowsingResponse, ITag } from '../../types';
import { addTagsToParamObj, getFilterCategories, notifyAxiosError } from '../../utils';

interface IProps {
    isSearchModalOpen: boolean;
    data: ISmartBrowsingResponse;
    setSearchModalOpen: (value: boolean) => void;
}

const Search: React.FC<IProps> = ({ data, isSearchModalOpen, setSearchModalOpen }) => {
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
    const [filterData, setFilterData] = useState<string[]>([]);
    const [freeText, setFreeText] = useState<string>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { selectedTab } = useContext(CommandDataContext);

    const params: any = useMemo(() => {
        const param = searchParams.get('req');
        setFreeText(undefined);
        if (param) {
            const parsedParam = JSON.parse(param);
            if (parsedParam.freeText) setFreeText(parsedParam.freeText);
            return parsedParam;
        } else return null;
    }, [searchParams]);

    useEffect(() => {
        if (params && !params.aggregationId) {
            getFiltersList({
                tags: params.tags,
                freeText: params.freeText,
                isFreeBrowsing: selectedTab === HOME_TABS[1]?.value,
            })
                .then((res) => setFilterData(res.data))
                .catch((err) => notifyAxiosError(err));
        } else {
            setFilterData(getFilterCategories(data));
        }
    }, [params, data, selectedTab]);

    useEffect(() => {
        if (!params?.tags) setFilterValues({});
        else {
            const newFilters: any = {};
            params.tags.forEach((tag: ITag) => {
                if (!filterData.includes(tag.category) || !tag.value || tag.value[0] === null)
                    return;
                newFilters[tag.category] = tag.value.map((val) => ({
                    value: val,
                    label: val,
                    readOnly: tag.readOnly,
                }));
            });
            setFilterValues(newFilters);
        }
    }, [searchParams, filterData]);

    const onFilterChange = useCallback(
        (values: { [key: string]: any }) => {
            addTagsToParamObj(params, values);
            navigate(`?req=${encodeURIComponent(JSON.stringify(params))}`);
        },
        [params],
    );

    const onFreeTextChange = useDebounce((text: string) => {
        try {
            let tempParams = params;
            if (!tempParams) {
                tempParams = {
                    freeText: text,
                    aggregationId: '0',
                };
            } else {
                tempParams.freeText = text;
            }
            navigate(`?req=${encodeURIComponent(JSON.stringify(tempParams))}`);
        } catch (err) {
            console.error(err);
        }
    }, 500);

    const loadOptions = useCallback(
        (inputValue: string, category: string): any => {
            return getFilterValues({
                tags: params?.tags ?? [
                    {
                        category: 'Folder',
                        value: ['/'],
                    },
                ],
                field: category,
                filterFreeText: inputValue,
                freeText: freeText,
            })
                .then((res) =>
                    res.status === 200
                        ? res.data.map((item: { id: string; value: string }) => ({
                              value: item.id,
                              label: item.value,
                          }))
                        : [],
                )
                .catch((err) => {
                    notifyAxiosError(err);
                });
        },
        [params],
    );

    const onFilterReset = useCallback(() => {
        const newParams: any = {};
        if (params) {
            if (params.tags)
                newParams.tags = params.tags.filter(
                    (tag: ITag) => !filterValues[tag.category] || tag.readOnly,
                );
            newParams.aggregationId = params.aggregationId;
            if (params.freeText) {
                setFreeText(undefined);
            }
        }
        navigate(`?req=${encodeURIComponent(JSON.stringify(newParams))}`);
    }, [filterValues, params]);

    const clearFilterDisabled =
        (Object.keys(filterValues).length === 0 ||
            Object.keys(filterValues).every(
                (key) => filterValues[key].filter((val: any) => !val.readOnly).length === 0,
            )) &&
        !!!freeText;

    return (
        <FilterContainer isFilterModalOpen={isSearchModalOpen}>
            <div className="flex justify-between items-center mb-8 md:hidden">
                <h3 className="uppercase font-bold">Filters</h3>
                <ClearIcon className="w-4 h-4" onClick={() => setSearchModalOpen(false)} />
            </div>
            <div className="flex flex-wrap gap-[10px] items-center justify-center md:justify-start">
                <div className={`w-full group md:w-[calc(100%-50px)]`}>
                    <SearchBox
                        value={freeText}
                        onChange={(newValue) => {
                            setFreeText(newValue);
                            onFreeTextChange(newValue);
                        }}
                    />
                </div>

                <ClearFilterButton disabled={clearFilterDisabled} onClick={onFilterReset} />

                {filterData?.map((category, idx) => (
                    <FilterSelect
                        params={params}
                        category={category}
                        loadOptions={loadOptions}
                        filterValues={filterValues}
                        onFilterChange={onFilterChange}
                        setFilterValues={setFilterValues}
                        key={`${JSON.stringify(params)}${idx}`}
                    />
                ))}
            </div>
        </FilterContainer>
    );
};

export default React.memo(Search);
